import { Injectable } from '@angular/core';
import { DimensionScale } from '../../interfaces/dimension-scale';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContainerService {

  /**
   * Unit equivalence
   * @protected
   */
  protected unit = environment.baseUnit;


  /**
   * Canvas height
   * @protected
   */
  protected height = 1080;

  /**
   * Canvas width
   * @protected
   */
  protected width = 1920;

  /**
   * Scale for height, width
   * @protected
   */
  protected scale: DimensionScale = {height: 1, width: 1};

  constructor() {
  }

  public resetDefaultDimension(): void {
    // this.height = window.innerHeight;
    // this.width = window.innerWidth;
  }

  /**
   * Returns height
   */
  public getHeight(): number {
    return this.height * this.getScale().height;
  }

  /**
   * Returns width
   */
  public getWidth(): number {
    return this.width * this.getScale().width;
  }

  /**
   * Re calculates scale
   * @param dimension
   */
  public reCalculateScale(dimension: DimensionScale): void {
    this.scale.width = dimension.width / this.width;
    this.scale.height = dimension.height / this.height;
    console.log(this.scale, dimension, this.getHeight(), this.getWidth());
  }

  /**
   * Returns scale
   */
  public getScale(): DimensionScale {
    return this.scale;
  }

  /**
   * Returns the unit
   */
  public getUnit(dimensionFor: 'height' | 'width' = 'width'): number {
    return this.unit * ((dimensionFor === 'width') ? this.scale.width : this.scale.height);
  }
}
