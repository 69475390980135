import Konva from 'konva';
import Rect = Konva.Rect;
import { DimensionScale } from '../../interfaces/dimension-scale';
import Group = Konva.Group;
import { ShapeNames } from '../../interfaces/shape-names';

export class GridSlot {

  /**
   * Base inner rectangle
   * @protected
   */
  protected innerRect: Rect;

  /**
   * Base outer rectangle
   * @protected
   */
  protected outerRect: Rect;

  /**
   * Grid slot group
   * @protected
   */
  protected group: Group;

  constructor(dimension: DimensionScale) {
    this.innerRect = new Rect({
      x: this.getStrokeWidth(dimension, 'height') / 2,
      y: this.getStrokeWidth(dimension, 'width') / 2,
      fill: 'white',
      height: dimension.height - this.getStrokeWidth(dimension, 'height'),
      width: dimension.width - this.getStrokeWidth(dimension, 'width')
    });

    this.outerRect = new Rect({
      x: 0,
      y: 0,
      fill: 'black',
      height: dimension.height,
      width: dimension.width,
      name: ShapeNames.GridSlot
    });

    this.group = new Group({
      x: 0,
      y: 0,
    });

    this.group.add(this.outerRect, this.innerRect);
  }

  /**
   * Returns stroke width accordance to dimension
   * @param dimension
   * @param strokeFor
   * @protected
   */
  protected getStrokeWidth(dimension: DimensionScale, strokeFor: 'height' | 'width'): number {
    return 0.01 * dimension[strokeFor];
  }

  /**
   * Returns base inner shape
   */
  public getInnerRect(): Rect {
    return this.innerRect;
  }

  /**
   * Returns base outer shape
   */
  public getOuterRect(): Rect {
    return this.outerRect;
  }

  /**
   * Get group maintaining the shape
   */
  public getGroup(): Group {
    return this.group;
  }
}
