import Konva from 'konva';
import {
  ActiveShape,
  ActiveShapeState,
  BaseShape,
  ReduceSizeOnDrag,
  ShapeIsDraggable,
  SnapToGrid
} from '../../interfaces/shape-actions';
import { Subscription } from 'rxjs';
import { ActiveBackground } from '../active-background/active-background';
import { DimensionScale } from '../../interfaces/dimension-scale';
import { generateUniqueName, snapCalculation } from '../../utils/helpers';
import { IRect } from 'konva/types/types';
import { informationEmitter, InformationEmitterKeys } from '../../utils/information-emitter';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import Group = Konva.Group;
import Rect = Konva.Rect;
import ContainerConfig = Konva.ContainerConfig;
import Text = Konva.Text;
import Image = Konva.Image;

export class TextContent extends Group implements BaseShape, ShapeIsDraggable, SnapToGrid, ReduceSizeOnDrag, ActiveShape {

  /**
   * Active shape state
   */
  public activeShapeState: ActiveShapeState = {
    detachable: false,
    removable: true,
    isActive: false,
    canBeActivated: true,
  };

  // @ts-ignore
  private activeShapeSubscription: Subscription;

  // @ts-ignore
  protected activeBackground: ActiveBackground;

  /**
   * Returns the unique name
   * @protected
   */
  protected uniqueName: string;

  protected baseRect: Rect;

  protected textContent: Text;

  protected pencilImage: Image | null = null;

  protected moveImage: Image | null = null;

  protected transformer: Konva.Transformer;

  public textarea: HTMLTextAreaElement;

  protected layerClickEvent: Subscription;

  protected zoomEvents: Subscription;

  constructor(
    public dialog: MatDialog,
    protected tileDimension: DimensionScale,
    public baseUnit: number,
    protected containerConfig?: ContainerConfig
  ) {
    super(containerConfig);
    this.uniqueName = generateUniqueName();
    this.baseRect = new Rect({
      x: 0,
      y: 0,
      width: 60 * this.baseUnit,
      height: 40 * this.baseUnit,
      strokeWidth: 0.2 * this.baseUnit,
      fill: 'transparent',
      stroke: 'black'
    });
    this.textContent = new Text({
      x: 0,
      y: 0,
      width: 60 * this.baseUnit,
      height: 40 * this.baseUnit,
      fontFamily: `Open Sans`,
      fontSize: 16,
      lineHeight: 1.5,
      ellipsis: true,
    });

    this.setupActiveBackgroundShape();

    this.add(this.baseRect);
    this.add(this.textContent);
    this.textContent.strokeScaleEnabled(false);
    this.textarea = document.createElement('textarea');
    this.textarea.style.position = 'absolute';
    this.textarea.style.zIndex = '100';
    this.textarea.classList.add('d-none');
    document.body.append(this.textarea);

    this.transformer = new Konva.Transformer({
      rotateEnabled: false,
      // @ts-ignore
      nodes: [this.baseRect, this.textContent],
      centeredScaling: true,
      ignoreStroke: true,
      enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
      boundBoxFunc: (oldBoundBox, newBoundBox) => {
        // "boundBox" is an object with
        // x, y, width, height and rotation properties
        // transformer tool will try to fit nodes into that box

        // the logic is simple, if new width is too big
        // we will return previous state
        if (Math.abs(newBoundBox.width) <= 60 * this.baseUnit || Math.abs(newBoundBox.width) >= 1920) {
          return oldBoundBox;
        }

        if (Math.abs(newBoundBox.height) <= 40 * this.baseUnit || Math.abs(newBoundBox.height) >= 1080) {
          return oldBoundBox;
        }

        return newBoundBox;
      },
    });
    this.transformer.hide();
    this.add(this.transformer);
    // @ts-ignore
    this.add(this.activeBackground);
    this.textContent.on('click tap', () => this.showTextArea());
    this.layerClickEvent = this.layerClickEventSubscription();

    this.manageShapesInTransform();
    this.initiateSnapToGrid();
    this.initiateReduceSizeEvents();
    this.initiateActiveShapeEvents();
    this.dragBoundFunc((pos) => {
      if (pos.x <= 20) {
        pos.x = 20;
        return pos;
      }

      if (pos.y <= 40) {
        pos.y = 40;
        return pos;
      }

      const stage = this.getLayer()?.getStage();
      if (!!stage) {
        if (pos.x >= stage.width() - 20) {
          pos.x = stage.width() - 20;
          return pos;
        }

        if (pos.y >= stage.height() - 175) {
          pos.y = stage.height() - 175;
          return pos;
        }
      }
      return pos;
    });
    this.createPencilImage();
    this.createMoveImage();
    this.zoomEvents = informationEmitter
      .asObservable()
      .pipe(filter(event => event.key === InformationEmitterKeys.ZoomIn || event.key === InformationEmitterKeys.ZoomOut))
      .subscribe(() => this.hideTextArea());
  }

  protected layerClickEventSubscription(): Subscription {
    return informationEmitter.asObservable().pipe(filter(event => event.key === InformationEmitterKeys.LayerClicked))
      .subscribe((value) => {
        if (value.shape instanceof TextContent) {
          if (value.shape.getUniqueName() !== this.uniqueName) {
            this.hideTextArea();
          }
          return;
        }

        if (value.shape instanceof Konva.Text) {
          if ((value.shape.parent as any).getUniqueName() !== this.getUniqueName()) {
            this.hideTextArea();
          }
          return;
        }

        if (value.shape?.parent instanceof TextContent) {
          if ((value.shape.parent).getUniqueName() !== this.getUniqueName()) {
            this.hideTextArea();
          }
          return;
        }

        if (value.shape?.parent?.parent instanceof TextContent) {
          if ((value.shape.parent?.parent as any).getUniqueName() !== this.getUniqueName()) {
            this.hideTextArea();
          }
          return;
        }

        this.hideTextArea();
      });
  }

  protected createPencilImage(): void {
    Konva.Image.fromURL('/assets/edit-pencil-text.png', (imageNode: Konva.Image) => {
      imageNode.setAttrs({
        x: -4 * this.baseUnit,
        y: -4 * this.baseUnit,
        width: this.tileDimension.width * 0.8,
        height: this.tileDimension.width * 0.8,
      });
      this.pencilImage = imageNode;
      this.getLayer()?.batchDraw();
    });
  }

  protected createMoveImage(): void {
    Konva.Image.fromURL('/assets/move-text.png', (imageNode: Konva.Image) => {
      imageNode.setAttrs({
        x: -4 * this.baseUnit,
        y: -4 * this.baseUnit,
        width: this.tileDimension.width * 0.8,
        height: this.tileDimension.width * 0.8,
      });
      this.moveImage = imageNode;
      this.moveImage.hide();
      this.getLayer()?.batchDraw();
    });
  }

  /**
   * Returns unique name of object
   */
  public getUniqueName(): string {
    return this.uniqueName;
  }

  /**
   * Creates a copy of current object
   */
  public copy(): BaseShape {
    return new TextContent(this.dialog, this.tileDimension, this.baseUnit, this.containerConfig);
  }

  /**
   * Disables drag
   */
  public disableDrag(): void {
    this.draggable(false);
  }

  /**
   * Enables drag
   */
  public enableDrag(): void {
    this.draggable(true);
  }

  /**
   * Returns the main group
   */
  public getShape(): BaseShape {
    return this;
  }

  /**
   * Initiates the events to to handle snap to grid actions
   */
  public initiateSnapToGrid(): void {
    this.getShape().on('dragend', () => this.snapToGrid(this.baseUnit));
  }

  /**
   * Sets new base unit
   * @param baseUnit
   */
  public setBaseUnit(baseUnit: number): void {
    this.baseUnit = baseUnit;
  }

  /**
   * Snaps the shape to grid
   * @param unit
   */
  public snapToGrid(unit: number): void {
    this.getShape().position(snapCalculation(this.getShape().position(), unit));
  }


  /**
   * Reduce size according to reduction logic
   */
  public reduceSize(): void {
    this.scale({ x: this.reduceSizeBy(), y: this.reduceSizeBy() });
  }

  /**
   * Initiate events to tackle reduction in size
   */
  public initiateReduceSizeEvents(): void {
    this.getShape().on('dragstart', () => this.reduceSize());
    this.getShape().on('dragend', () => this.revertToOriginalSize());
  }

  /**
   * Reverts back to original size
   */
  public revertToOriginalSize(): void {
    this.scale({ x: 1, y: 1 });
  }

  /**
   * Reduction size multiplier
   * Range is from 0 to 1.
   */
  public reduceSizeBy(): number {
    return 0.5;
  }

  /**
   * Returns intersection shape dimension to check
   */
  public intersection(): IRect {
    return this.getClientRect();
  }

  public initiateActiveShapeEvents(): void {
    this.on('dragstart', () => this.canBeActive() ? this.setActive(true) : '');
    this.on('touchstart', () => this.canBeActive() ? this.setActive(true) : '');
    this.on('click', () => this.canBeActive() ? this.setActive(true) : '');
    this.activeShapeSubscription = informationEmitter.asObservable()
      .pipe(filter(event => event.key === InformationEmitterKeys.ActiveShapeChanged))
      .pipe(filter(event => event.uniqueName !== this.getUniqueName()))
      .subscribe(() => {
        this.setActive(false);
      });
  }

  /**
   * Returns true if shape is removable
   */
  public canBeRemoved(): boolean {
    return this.activeShapeState.removable;
  }

  /**
   * Returns true if shape is detachable
   */
  public canBeDetached(): boolean {
    return this.activeShapeState.detachable;
  }

  /**
   * Sets the removable state of shape
   * @param allowRemoval
   */
  public allowRemoval(allowRemoval = true): void {
    this.activeShapeState.removable = allowRemoval;
  }

  /**
   * Sets the detachable state of shape
   * @param allowDetachment
   */
  public allowDetachment(allowDetachment = true): void {
    this.activeShapeState.detachable = allowDetachment;
  }

  /**
   * Returns true if current shape is active
   */
  public isActive(): boolean {
    return this.activeShapeState.isActive;
  }

  /**
   * Setup active background shape for current shape
   */
  public setupActiveBackgroundShape(): void {
    this.activeBackground = new ActiveBackground(this.baseUnit);
    this.activeBackground.add(new Rect({
      height: this.baseRect.height(),
      width: this.baseRect.width(),
      strokeWidth: 0.5 * this.baseUnit,
      fill: 'rgba(128, 128, 128, 0.4)',
      stroke: 'rgba(128, 128, 128)',
    }));
    this.activeBackground.hide();
    this.add(this.activeBackground);
  }

  /**
   * Sets the shape as active/inactive
   * @param active
   */
  public setActive(active = true): void {
    this.activeShapeState.isActive = active;
    this.isActive() ? this.getActiveBackground().show() : this.getActiveBackground().hide();
    if (this.isActive()) {
      informationEmitter.emit({
        key: InformationEmitterKeys.ActiveShapeChanged,
        shape: this,
        uniqueName: this.getUniqueName()
      });
    }
  }

  /**
   * Returns active background shape that maintains visual state of active shape
   */
  public getActiveBackground(): BaseShape {
    return this.activeBackground;
  }

  /**
   * Triggers detach action (detaches linked shape from another shape)
   */
  public triggerDetach(): void {
    this.destroy();
  }

  /**
   * Triggers remove action (equal to trash)
   */
  public triggerRemove(): void {
    this.destroy();
  }

  /**
   * Allows the shape to be tackle active state handling
   * @param allow
   * @protected
   */
  public allowActivation(allow: boolean): void {
    this.activeShapeState.canBeActivated = allow;
    if (this.isActive() && !this.activeShapeState.canBeActivated) {
      this.setActive(false);
    }
  }

  /**
   * Returns true if the shape can be activated
   */
  public canBeActive(): boolean {
    return this.activeShapeState.canBeActivated;
  }

  public destroy(): this {
    this.activeShapeSubscription.unsubscribe();
    this.textarea.remove();
    this.layerClickEvent.unsubscribe();
    this.zoomEvents.unsubscribe();
    return super.destroy();
  }

  public showTextArea(): void {
    this.disableDrag();
    this.textarea.classList.remove('d-none');
    this.textarea.style.top = `${this.baseRect.getAbsolutePosition().y.toString()}px`;
    this.textarea.style.left = `${this.baseRect.getAbsolutePosition().x.toString()}px`;
    this.textarea.style.height = `${this.baseRect.height() * (this.getLayer()?.scaleY() || 1)}px`;
    this.textarea.style.width = `${this.baseRect.width() * (this.getLayer()?.scaleX() || 1)}px`;
    this.transformer.hide();
    this.textarea.value = this.textContent.text();
    this.getLayer()?.draw();
    this.textarea.focus();
  }

  public hideTextArea(): void {
    this.enableDrag();
    this.textarea.classList.add('d-none');
    this.textContent.text(this.textarea.value);
    this.transformer.show();
  }

  public enableShadow(enable = true): void {
    this.baseRect.shadowEnabled(enable);
  }

  /**
   * Transform event to convert the scaled value to active property values
   * @protected
   */
  protected manageShapesInTransform(): void {
    this.textContent.on('transform', () => {
      this.textContent.setAttrs({
        scaleX: 1,
        scaleY: 1,
        width: Math.max(this.textContent.width() * this.textContent.scaleX(), 60 * this.baseUnit),
        height: Math.max(this.textContent.height() * this.textContent.scaleY(), 40 * this.baseUnit),
      });
      this.activeBackground.children[0].setAttrs({
        x: 0,
        y: 0,
        width: this.textContent.width(),
        height: this.textContent.height(),
      });
      this.activeBackground.position(this.textContent.position());
      console.log(this.textContent.position(), this.activeBackground.position(), this.activeBackground.children[0]);
    });

    this.baseRect.on('transform', () => {
      this.baseRect.setAttrs({
        scaleX: 1,
        scaleY: 1,
        width: Math.max(this.baseRect.width() * this.baseRect.scaleX(), 60 * this.baseUnit),
        height: Math.max(this.baseRect.height() * this.baseRect.scaleY(), 40 * this.baseUnit),
      });
    });
  }
}
