import Konva from 'konva';
import Group = Konva.Group;
import Rect = Konva.Rect;
import { BaseShape } from '../../interfaces/shape-actions';
import { DimensionScale } from '../../interfaces/dimension-scale';
import { ShapeNames } from '../../interfaces/shape-names';
import { generateUniqueName } from '../../utils/helpers';
import ContainerConfig = Konva.ContainerConfig;
import { EmptyTenFrames } from '../empty-ten-frames/empty-ten-frames';
import { IRect } from 'konva/types/types';

export class HundredFrameTenGroup extends Group implements BaseShape {

  /**
   * Base Empty Rect
   * @protected
   */
  protected baseRect: Rect;

  /**
   * Unique name of object
   * @protected
   */
  protected uniqueName: string;

  constructor(tileDimension: DimensionScale, public baseUnit: number = 10, containerConfig?: ContainerConfig) {
    super(containerConfig);
    this.setPosition({x: containerConfig?.x || 0, y: containerConfig?.y || 0});
    this.name(ShapeNames.TenFrameTileGroup);
    this.baseRect = new Rect({
      x: 0,
      y: 0,
      fill: 'transparent',
      stroke: 'black',
      height: EmptyTenFrames.FrameDimension(tileDimension, baseUnit, 'width'),
      width: EmptyTenFrames.FrameDimension(tileDimension, baseUnit, 'height'),
      strokeWidth: this.getStrokeWidth(baseUnit)
    });
    this.uniqueName = generateUniqueName();
    this.add(this.baseRect);

  }

  /**
   * Returns the stroke width
   * @param unit
   * @protected
   */
  protected getStrokeWidth(unit: number): number {
    return unit * 0.1;
  }

  /**
   * Base shape
   */
  public getShape(): this {
    return this;
  }

  /**
   * Set the base unit
   * @param baseUnit
   */
  public setBaseUnit(baseUnit: number): void {
    this.baseUnit = baseUnit;
  }

  public copy(): BaseShape {
    return this;
  }

  /**
   * Returns unique name
   */
  public getUniqueName(): string {
    return this.uniqueName;
  }

  public intersection(): IRect {
    return this.baseRect.getClientRect();
  }
}
