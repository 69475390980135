import Konva from 'konva';
import Rect = Konva.Rect;
import ContainerConfig = Konva.ContainerConfig;

export class SelectingShape extends Rect {
  constructor(protected containerConfig?: ContainerConfig) {
    super(containerConfig);
    this.fill('rgba(0,0,255,0.5)');
    this.visible(false);
    this.name('selection-box');
  }
}
