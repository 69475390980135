import Konva from 'konva';
import Group = Konva.Group;
import { BaseShape } from '../../interfaces/shape-actions';
import ContainerConfig = Konva.ContainerConfig;
import { generateUniqueName } from '../../utils/helpers';
import { ShapeNames } from '../../interfaces/shape-names';
import { IRect } from 'konva/types/types';

export class Menu extends Group implements BaseShape {

  protected uniqueName: string;

  constructor(public baseUnit: number, protected containerConfig?: ContainerConfig) {
    super(containerConfig);
    this.uniqueName = generateUniqueName();
    this.name(ShapeNames.LeftMenu);
  }

  copy(): BaseShape {
    return new Menu(this.baseUnit, this.containerConfig);
  }

  getShape(): BaseShape {
    return this;
  }

  getUniqueName(): string {
    return this.uniqueName;
  }

  setBaseUnit(baseUnit: number): void {
    this.baseUnit = baseUnit;
  }

  public intersection(): IRect {
    return this.getClientRect();
  }
}
