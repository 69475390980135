import { generateUniqueName } from '../../utils/helpers';
import { BaseShape } from '../../interfaces/shape-actions';
import { IRect } from 'konva/types/types';
import Konva from 'konva';
import Group = Konva.Group;
import ContainerConfig = Konva.ContainerConfig;

export class ActiveBackground extends Group implements BaseShape {
  /**
   * Sets the unique name
   * @protected
   */
  protected uniqueName: string;

  constructor(public baseUnit: number, protected containerConfig?: ContainerConfig) {
    super(containerConfig);
    this.uniqueName = generateUniqueName();
  }

  copy(): BaseShape {
    return new ActiveBackground(this.baseUnit, this.containerConfig);
  }

  getShape(): BaseShape {
    return this;
  }

  getUniqueName(): string {
    return this.uniqueName;
  }

  intersection(): IRect {
    return this.getClientRect();
  }

  setBaseUnit(baseUnit: number): void {
    this.baseUnit = baseUnit;
  }

  public show (): this {
    // this.moveToTop();
    return super.show();
  }
}
