import { Injectable } from '@angular/core';
import { ContainerService } from '../client/container.service';
import { Tile } from '../../shapes/tile/tile';
import Konva from 'konva';
import Vector2d = Konva.Vector2d;
import { DimensionScale } from '../../interfaces/dimension-scale';
import { environment } from '../../../environments/environment';
import { EmptyTenFrames } from '../../shapes/empty-ten-frames/empty-ten-frames';
import { YellowTenFrame } from '../../shapes/yellow-ten-frame/yellow-ten-frame';
import { EmptyHundredFrame } from '../../shapes/empty-hundred-frame/empty-hundred-frame';
import { BlueHundredFrame } from '../../shapes/blue-hundred-frame/blue-hundred-frame';
import { TextContent } from '../../shapes/text-content/text-content';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ShapesService {

  /**
   * Tile height
   * @protected
   */
  protected tileSize = environment.tile.size;

  constructor(
    public containerService: ContainerService,
    public dialog: MatDialog,
  ) {
  }

  /**
   * Returns new tile instance
   * @param position
   */
  public createTile(position: Vector2d = {x: 0, y: 0}): Tile {

    const tile = new Tile(this.getTileSize(), this.containerService.getUnit());
    tile.getShape().position(position);
    return tile;
  }

  /**
   * Returns base the base tile size being used in the system
   */
  public getTileSize(): DimensionScale {
    const unit = this.containerService.getUnit();
    return {height: unit * this.tileSize, width: unit * this.tileSize};
  }

  /**
   * Creates an empty ten frame
   */
  public createEmptyTenFrames(): EmptyTenFrames {
    return new EmptyTenFrames(this.getTileSize(), this.containerService.getUnit());
  }

  /**
   * Returns a yellow ten frame
   */
  public createYellowTenFrame(): YellowTenFrame {
    return new YellowTenFrame(this.getTileSize(), this.containerService.getUnit());
  }

  public createEmptyHundredFrame(): EmptyHundredFrame {
    return new EmptyHundredFrame(this.getTileSize(), this.containerService.getUnit());
  }

  /**
   * Returns a blue hundred frame
   */
  public createBlueHundredFrame(): BlueHundredFrame {
    return new BlueHundredFrame(this.getTileSize(), this.containerService.getUnit());
  }

  /**
   * Returns a text content
   */
  public createTextContent(): TextContent {
    return new TextContent(this.dialog, this.getTileSize(), this.containerService.getUnit());
  }

}
