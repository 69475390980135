import { Injectable } from '@angular/core';
import { ContainerService } from '../client/container.service';
import Konva from 'konva';
import Stage = Konva.Stage;
import Layer = Konva.Layer;
import { informationEmitter, InformationEmitterKeys } from '../../utils/information-emitter';
import { SelectingShape } from '../../shapes/selecting-shape/selecting-shape';
import { Tile } from '../../shapes/tile/tile';
import { EmptyTenFrames } from '../../shapes/empty-ten-frames/empty-ten-frames';
import { BlueHundredFrame } from '../../shapes/blue-hundred-frame/blue-hundred-frame';
import { EmptyHundredFrame } from '../../shapes/empty-hundred-frame/empty-hundred-frame';
import { YellowTenFrame } from '../../shapes/yellow-ten-frame/yellow-ten-frame';
import { timer } from 'rxjs';
import Vector2d = Konva.Vector2d;

@Injectable({
  providedIn: 'root'
})
export class StageService {

  /**
   * Stage entity where content will be drawn/animated
   * @protected
   */
  protected stage: Stage | undefined = undefined;

  constructor(
    public containerService: ContainerService
  ) {
  }

  public initialize(containerId: string, width: number = 10000, height: number = 10000): void {
    this.stage = new Stage({
      container: containerId,
      height,
      width
    });
    this.initializeClickHandler();
  }

  public initializeClickHandler(): void {
    this.getStage().on('click tap dragstart touchstart', (event) => {
      informationEmitter.emit({
        shape: event.target as any,
        uniqueName: '',
        key: InformationEmitterKeys.LayerClicked
      });
    });
  }

  /**
   * Returns the stage
   */
  public getStage(): Stage {
    if (!this.stage) {
      throw new Error('Stage not yet initialized');
    }
    return this.stage;
  }

  /**
   * Adds layer to stage
   * @param layer
   */
  public addLayer(layer: Layer): void {
    this.getStage().add(layer);
  }

  /**
   * Rest stage dimensions
   */
  public resetDimensions(): void {
    this.getStage().width(this.containerService.getWidth());
    this.getStage().height(this.containerService.getHeight());
    this.stage?.scale({x: this.containerService.getScale().width, y: this.containerService.getScale().height});
    this.getStage().batchDraw();
  }
}
