import { DimensionScale } from '../../interfaces/dimension-scale';
import Konva from 'konva';
import {
  ActiveShape,
  ActiveShapeState,
  BaseShape,
  ReduceSizeOnDrag,
  ShapeIsDraggable,
  ShapeToParentActions,
  SnapToGrid,
  Stackable
} from '../../interfaces/shape-actions';
import { generateUniqueName, snapCalculation } from '../../utils/helpers';
import { ShapeNames } from '../../interfaces/shape-names';
import { informationEmitter, InformationEmitterKeys } from '../../utils/information-emitter';
import { IRect } from 'konva/types/types';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ActiveBackground } from '../active-background/active-background';
import Group = Konva.Group;
import Rect = Konva.Rect;
import Circle = Konva.Circle;
import ContainerConfig = Konva.ContainerConfig;
import Layer = Konva.Layer;

export class Tile extends Group implements ShapeIsDraggable,
  BaseShape,
  ShapeToParentActions,
  SnapToGrid,
  ReduceSizeOnDrag,
  ActiveShape,
  Stackable {

  /**
   * Active shape state
   */
  public activeShapeState: ActiveShapeState = {
    detachable: false,
    removable: true,
    isActive: false,
    canBeActivated: true
  };

  // @ts-ignore
  private activeShapeSubscription: Subscription;

  /**
   * Sets the unique name
   * @protected
   */
  protected uniqueName: string;

  /**
   * Main rectangle container
   * @protected
   */
  protected baseRect: Rect;

  // @ts-ignore
  protected activeBackground: ActiveBackground;

  /**
   * The stacked item
   */
  public stackedItem: BaseShape & Stackable | null = null;

  /**
   * The parent stack for current object
   */
  public parentStack: BaseShape & Stackable | null = null;

  /**
   * Stack count
   */
  public stackCount = 0;

  public attachedToTenFrame = false;

  protected circle: Circle;

  protected text: Konva.Text;

  /**
   * Information emitter subscription
   * @private
   */
  private tileChangeSubscription: Subscription;

  public countManager: number;

  constructor(protected dimension: DimensionScale, public baseUnit: number, protected containerConfig?: ContainerConfig) {
    super(containerConfig);
    this.countManager = (window as any).CounterManager;
    (window as any).CounterManager++;
    this.baseRect = new Rect({
      x: 0,
      y: 0,
      width: dimension.width,
      height: dimension.height,
      fill: '#E69A4B',
      shadowEnabled: true,
      shadowOffset: { x: 3, y: 5 },
      shadowOpacity: 0.4,
      shadowColor: '#854700',
      shadowBlur: 4,
    });
    this.name(ShapeNames.Tile);
    this.uniqueName = generateUniqueName();
    this.add(this.baseRect);
    this.circle = new Circle({
      radius: dimension.width * 0.2,
      fill: 'black',
      opacity: 0.2,
      x: dimension.width / 2,
      y: dimension.height / 2,
    });
    this.text = new Konva.Text({
      fill: 'black',
      width: dimension.width,
      height: dimension.height,
      align: 'center',
      text: '0',
      fontStyle: 'bold',
      verticalAlign: 'middle',
      fontSize: 15
    });
    this.text.hide();
    this.add(this.text);
    this.add(this.circle);
    this.initiateReduceSizeEvents();
    this.initiateSnapToGrid();
    this.initiateActiveShapeEvents();
    this.setupActiveBackgroundShape();
    this.tileChangeSubscription = this.tileChangeEvents();
    this.on('dragstart', () => {
      informationEmitter.emit({
        key: InformationEmitterKeys.ReOrderShapes,
        shape: this,
        uniqueName: this.getUniqueName()
      });
      if (!(this.parent instanceof Layer)) {
        this.detach();
      }
    });
    this.dragBoundFunc((pos) => {
      if (pos.x <= 10) {
        pos.x = 10;
      }

      if (pos.y <= 10) {
        pos.y = 10;
      }

      const stage = this.getLayer()?.getStage();
      if (!!stage) {
        if (pos.x >= stage.width() - 20) {
          pos.x = stage.width() - 20;
        }

        if (pos.y >= stage.height() - 85) {
          pos.y = stage.height() - 85;
        }
      }
      return pos;
    });
  }

  /**
   * Returns group
   */
  public getShape(): this {
    return this;
  }

  public getUniqueName(): string {
    return this.uniqueName;
  }

  /**
   * Enable drag on group
   */
  public enableDrag(): void {
    this.getShape().draggable(true);
  }

  /**
   * Disable drag on group
   */
  public disableDrag(): void {
    this.getShape().draggable(false);
  }

  /**
   * Detaches from parent
   */
  public detach(): void {
    this.getShape().moveTo(this.getShape().getLayer());
    this.getShape().setName(ShapeNames.Tile);
    informationEmitter.emit({
      key: InformationEmitterKeys.TileRemoved,
      uniqueName: this.getUniqueName(),
      shape: this
    });
    informationEmitter.emit({
      key: InformationEmitterKeys.ReOrderShapes,
      uniqueName: this.getUniqueName(),
      shape: this
    });
    this.allowDetachment(false);
    this.attachedToTenFrame = false;
    this.forceDetachSelfFromStack();
  }

  public initiateSnapToGrid(): void {
    this.getShape().on('dragend', () => this.snapToGrid(this.baseUnit));
  }

  /**
   * Snaps item to grid
   * @param unit
   */
  public snapToGrid(unit: number): void {
    this.getShape().position(snapCalculation(this.getShape().position(), unit));
  }

  public attach(): void {
    this.getShape().setName(ShapeNames.AttachedTile);
    this.attachedToTenFrame = true;
  }

  /**
   * Set the base unit
   * @param baseUnit
   */
  public setBaseUnit(baseUnit: number): void {
    this.baseUnit = baseUnit;
  }

  public copy(): Tile {
    const tile = new Tile(this.dimension, this.baseUnit, this.containerConfig);

    if (this.stackedItem) {
      const stackedItem = this.stackedItem.copy() as Tile;
      stackedItem.enableShadow(false);
      if (this.stackedItem.draggable()) {
        stackedItem.enableDrag();
      }
      tile.stackItem(stackedItem);
    }
    tile.resetStackCount();

    return tile;
  }

  /**
   * Reduce size according to reduction logic
   */
  public reduceSize(): void {
    this.scale({ x: this.reduceSizeBy(), y: this.reduceSizeBy() });
  }

  /**
   * Initiate events to tackle reduction in size
   */
  public initiateReduceSizeEvents(): void {
    this.getShape().on('dragstart', () => this.reduceSize());
    this.getShape().on('dragend', () => this.revertToOriginalSize());
  }

  /**
   * Reverts back to original size
   */
  public revertToOriginalSize(): void {
    this.scale({ x: 1, y: 1 });
  }

  /**
   * Reduction size multiplier
   * Range is from 0 to 1.
   */
  public reduceSizeBy(): number {
    return 0.5;
  }

  public intersection(): IRect {
    return this.baseRect.getClientRect();
  }

  public initiateActiveShapeEvents(): void {
    this.on('dragstart', () => this.canBeActive() ? this.setActive(true, true) : '');
    this.on('click tap', () => {
      if ((this.parent instanceof Layer) && this.canBeActive()) {
        this.setActive(true);
      }
    });
    this.activeShapeSubscription = informationEmitter.asObservable()
      .pipe(filter(event => event.key === InformationEmitterKeys.ActiveShapeChanged))
      .pipe(filter(event => event.uniqueName !== this.getUniqueName()))
      .pipe(filter(() => ((window as any).InMultiMode !== true)))
      .pipe(filter(() => this.isActive()))
      .subscribe(() => this.setActive(false));
  }

  /**
   * Returns true if shape is removable
   */
  public canBeRemoved(): boolean {
    return this.activeShapeState.removable;
  }

  /**
   * Returns true if shape is detachable
   */
  public canBeDetached(): boolean {
    return this.activeShapeState.detachable;
  }

  /**
   * Sets the removable state of shape
   * @param allowRemoval
   */
  public allowRemoval(allowRemoval = true): void {
    this.activeShapeState.removable = allowRemoval;
  }

  /**
   * Sets the detachable state of shape
   * @param allowDetachment
   */
  public allowDetachment(allowDetachment = true): void {
    this.activeShapeState.detachable = allowDetachment;
  }

  /**
   * Returns true if current shape is active
   */
  public isActive(): boolean {
    return this.activeShapeState.isActive;
  }

  /**
   * Setup active background shape for current shape
   */
  public setupActiveBackgroundShape(): void {
    this.activeBackground = new ActiveBackground(this.baseUnit);
    this.activeBackground.add(new Rect({
      height: this.baseRect.height(),
      width: this.baseRect.width(),
      strokeWidth: 0.5 * this.baseUnit,
      fill: 'rgba(128, 128, 128, 0.4)',
      stroke: 'rgba(128, 128, 128)',
    }));
    this.activeBackground.hide();
    this.add(this.activeBackground);
  }

  /**
   * Sets the shape as active/inactive
   * @param active
   * @param inDrag
   */
  public setActive(active = true, inDrag = false): void {
    const wasActive = this.isActive();
    const inMultiFlow = (window as any).InMultiMode;

    if (!this.canBeActive()) {
      active = false;
    }

    if (this.isActive() && inMultiFlow && !inDrag) {
      active = false;
    }

    this.activeShapeState.isActive = active;

    this.isActive() ? this.getActiveBackground().show() : this.getActiveBackground().hide();

    if (this.isActive()) {
      informationEmitter.emit({
        key: InformationEmitterKeys.ActiveShapeChanged,
        shape: this,
        uniqueName: this.getUniqueName()
      });
      this.getActiveBackground().moveToTop();
    }

    if (wasActive && !this.isActive()) {
      informationEmitter.emit({
        key: InformationEmitterKeys.ActiveShapeRemoved,
        shape: this,
        uniqueName: this.getUniqueName()
      });
    }

    if (inMultiFlow) {
      const isActive = this.isActive();
      let parentStack = this.parentStack as (Tile | null);
      while (parentStack) {
        parentStack.setActive(isActive, isActive ? true : inDrag);
        parentStack = parentStack.parentStack as (Tile | null);
      }
    }

    if (!inMultiFlow) {
      const isActive = this.isActive();
      let parentStack = this.parentStack as (Tile | null);
      while (parentStack) {
        parentStack.setActive(isActive, isActive ? true : inDrag);
        parentStack = parentStack.parentStack as (Tile | null);
      }
    }
  }

  /**
   * Returns active background shape that maintains visual state of active shape
   */
  public getActiveBackground(): BaseShape {
    return this.activeBackground;
  }

  /**
   * Triggers detach action (detaches linked shape from another shape)
   */
  public triggerDetach(): void {
    this.destroy();
  }

  /**
   * Triggers remove action (equal to trash)
   */
  public triggerRemove(): void {
    this.destroy();
  }

  public destroy(): this {
    informationEmitter.emit({
      key: InformationEmitterKeys.TileRemoved,
      shape: this,
      uniqueName: this.getUniqueName(),
    });
    this.activeShapeSubscription.unsubscribe();
    this.tileChangeSubscription.unsubscribe();
    return super.destroy();
  }

  /**
   * Allows the shape to be tackle active state handling
   * @param allow
   * @protected
   */
  public allowActivation(allow: boolean): void {
    this.activeShapeState.canBeActivated = allow;
    if (this.isActive() && !this.activeShapeState.canBeActivated) {
      this.setActive(false);
    }
  }

  /**
   * Returns true if the shape can be activated
   */
  public canBeActive(): boolean {
    return this.activeShapeState.canBeActivated;
  }

  public enableShadow(enable = true): void {
    this.baseRect.shadowEnabled(enable);
  }

  /**
   * Returns true when stacking is allowed
   */
  public canStack(): boolean {
    return this.attachedToTenFrame;
  }

  /**
   * Set stacked item
   * @param item
   * @param disableDrag
   * @param stackToSelf
   */
  stackItem(item: Tile, disableDrag = true, stackToSelf = false): void {
    if (!!this.stackedItem && !stackToSelf) {
      this.stackedItem.stackItem(item);
      return;
    }
    this.stackedItem = item;
    this.stackedItem.markSelfStacked(this.stackCount);
    this.stackedItem.setParentStack(this);
    this.add(item);
    if (disableDrag) {
      this.disableDrag();
    }

    if ((window as any).InMultiMode) {
      this.setActive(true, true);
      (item as Tile).setActive(true, true);
    }
  }

  public resetStackCount(): void {
    if (!!this.parentStack && this.parentStack?.resetStackCount) {
      return this.parentStack.resetStackCount();
    }

    this.stackCount = 0;
    let stackItem = this.stackedItem;
    let counter = this.stackCount;
    while (!!stackItem) {
      stackItem.stackCount = counter + 2;
      if (stackItem.updateCount) {
        stackItem.updateCount(stackItem.stackCount);
      }
      counter++;
      stackItem = stackItem.stackedItem;
    }
    this.getLayer()?.batchDraw();
  }

  public updateCount(count: number): void {
    this.text.text(count.toString());
  }

  /**
   * Remove stacked item
   */
  removeStackItem(): void {
    this.stackedItem?.removeSelfStacked();
    this.stackedItem?.setParentStack(null);
    this.stackedItem = null;
    this.enableDrag();
  }

  /**
   * Mark self object as stacked to parent
   * @param parentCount
   */
  public markSelfStacked(parentCount: number): void {
    this.stackCount = parentCount;
    this.stackCount++;
    this.showCount(true);
    this.position({ x: 0, y: 0 });
    this.getLayer()?.batchDraw();
  }

  /**
   * Hide show counter of stack
   * @param show
   */
  showCount(show: boolean): void {
    this.circle.show();
    this.text.hide();
    if (show) {
      this.circle.hide();
      this.text.show();
      this.text.text((this.totalStackCount() + 1).toString());
    }
  }

  /**
   * Remove self from parent stack
   */
  public removeSelfStacked(): void {
    this.stackCount = 0;
    if (!(this.parent instanceof Layer)) {
      this.getShape().moveTo(this.getShape().getLayer());
    }
    this.showCount(false);
  }

  /**
   * Set current parent stack object
   * @param item
   */
  public setParentStack(item: BaseShape & Stackable | null): void {
    this.parentStack = item;
  }

  /**
   * Detach from parent stack force fully
   */
  public forceDetachSelfFromStack(): void {
    this.parentStack?.removeStackItem();
  }

  /**
   * Returns total stack count
   */
  public totalStackCount(): number {
    if (!!this.stackedItem) {
      return this.stackedItem.totalStackCount();
    }

    return this.stackCount;
  }


  /**
   * Handles various tile events needed by this shape
   * @protected
   */
  protected tileChangeEvents(): Subscription {
    const allowedEventKeys: InformationEmitterKeys[] = [InformationEmitterKeys.TileRemoved];
    return informationEmitter.asObservable()
      .pipe(filter(result => allowedEventKeys.includes(result.key)))
      .subscribe(result => {
        if (this.stackedItem?.getUniqueName() === result.uniqueName) {
          this.removeStackItem();
        }
      });
  }
}
