import { DimensionScale } from '../../interfaces/dimension-scale';
import Konva from 'konva';
import {
  ActiveShape, ActiveShapeState,
  BaseShape,
  ReduceSizeOnDrag,
  Rotate90Deg,
  ShapeIsDraggable,
  ShapeToParentActions,
  SnapToGrid, Stackable
} from '../../interfaces/shape-actions';
import { Tile } from '../tile/tile';
import { checkIntersection, generateUniqueName, snapCalculation } from '../../utils/helpers';
import { TenFrameTileGroup } from '../ten-frame-tile-group/ten-frame-tile-group';
import { ShapeNames } from '../../interfaces/shape-names';
import { Subscription } from 'rxjs';
import { informationEmitter, InformationEmitterKeys } from '../../utils/information-emitter';
import { filter } from 'rxjs/operators';
import { YellowTenFrame } from '../yellow-ten-frame/yellow-ten-frame';
import { EmptyHundredFrame } from '../empty-hundred-frame/empty-hundred-frame';
import { IRect } from 'konva/types/types';
import Group = Konva.Group;
import Rect = Konva.Rect;
import ContainerConfig = Konva.ContainerConfig;
import Layer = Konva.Layer;
import { ActiveBackground } from '../active-background/active-background';
import Circle = Konva.Circle;
import KonvaEventObject = Konva.KonvaEventObject;
import Text = Konva.Text;

interface ColumnFrame {
  group: TenFrameTileGroup;
  tile: Tile | null;
}

interface RowFrame {
  row: number;
  columns: ColumnFrame[];
}

export class EmptyTenFrames extends Group implements BaseShape,
  ShapeIsDraggable,
  SnapToGrid,
  ShapeToParentActions,
  ReduceSizeOnDrag,
  Rotate90Deg,
  ActiveShape,
  Stackable {

  public static RowBaseSpaceMultiplier = 3;

  public static ColumnSpaceMultiplier = 7;

  /**
   * Active shape state
   */
  public activeShapeState: ActiveShapeState = {
    detachable: false,
    removable: true,
    isActive: false,
    canBeActivated: true,
  };

  // @ts-ignore
  private activeShapeSubscription: Subscription;

  // @ts-ignore
  protected activeBackground: ActiveBackground;

  /**
   * Sectional config of tiles by row and column
   * @protected
   */
  protected tileSectionConfig: RowFrame[] = [];

  /**
   * Objects unique name
   * @protected
   */
  protected uniqueName: string;

  /**
   * Information emitter subscription
   * @private
   */
  private tileChangeSubscription: Subscription;

  /**
   * Information emitter subscription
   * @private
   */
  private yellowFrameChangeSubscription: Subscription;

  /**
   * Information emitter subscription
   * @private
   */
  private emptyFrameChangeSubscription: Subscription;

  /**
   * Sets the rotation state
   * @protected
   */
  protected rotated = false;

  /**
   * Yellow ten frame
   * @protected
   */
  public yellowTenFrame: YellowTenFrame | null = null;

  /**
   * Empty Hundred frame
   * @protected
   */
  public emptyHundredFrame: EmptyHundredFrame | null = null;

  protected baseRect: Rect;

  /**
   * The stacked item
   */
  public stackedItem: BaseShape & Stackable | null = null;

  /**
   * The parent stack for current object
   */
  public parentStack: BaseShape & Stackable | null = null;

  /**
   * Stack count
   */
  public stackCount = 0;

  protected text: Konva.Text;

  public countManager: number;


  /**
   * Returns the frame width exclude the size of stroke
   * @param tileDimension
   * @param unit
   * @param forDimension
   * @constructor
   */
  public static FrameDimension(tileDimension: DimensionScale, unit: number, forDimension: 'height' | 'width'): number {
    const multiplier = forDimension === 'width' ? 5.2 : 2.2;
    const strokeWidth = (2 * EmptyTenFrames.StrokeWidth(unit));
    const outsideMargin = (
      (forDimension === 'width' ? EmptyTenFrames.ColumnSpaceMultiplier : EmptyTenFrames.RowBaseSpaceMultiplier) * unit
    );
    return (multiplier * tileDimension[forDimension]) - strokeWidth + outsideMargin;
  }


  /**
   * Returns the stroke width
   * @param unit
   * @constructor
   */
  public static StrokeWidth(unit: number): number {
    return 0.5 * unit;
  }


  constructor(private tileDimension: DimensionScale, public baseUnit: number, protected containerConfig?: ContainerConfig) {
    super(containerConfig);
    this.countManager = (window as any).CounterManager;
    (window as any).CounterManager++;
    this.name(ShapeNames.EmptyTenFrame);
    this.setPosition({ x: 0, y: 0 });

    this.baseRect = new Rect({
      x: 0,
      y: 0,
      width: EmptyTenFrames.FrameDimension(tileDimension, baseUnit, 'width'),
      height: EmptyTenFrames.FrameDimension(tileDimension, baseUnit, 'height'),
      strokeWidth: EmptyTenFrames.StrokeWidth(baseUnit),
      stroke: 'black',
      fill: 'white',
      name: 'outer-rect',
      shadowEnabled: true,
      shadowOffset: { x: 4, y: 5 },
      shadowBlur: 5,
      shadowOpacity: 0.4,
    });

    this.add(this.baseRect);
    this.text = new Konva.Text({
      width: EmptyTenFrames.FrameDimension(tileDimension, baseUnit, 'width'),
      height: EmptyTenFrames.FrameDimension(tileDimension, baseUnit, 'height'),
      align: 'center',
      verticalAlign: 'middle',
      fill: 'black',
      text: '0',
      fontSize: 15,
      fontStyle: 'bold',
      x: 0,
      y: 0,
    });
    this.add(this.text);
    this.text.hide();
    this.uniqueName = generateUniqueName();
    this.setupFrameSection();
    this.initiateSnapToGrid();
    this.tileChangeSubscription = this.tileChangeEvents();
    this.yellowFrameChangeSubscription = this.yellowFrameChangeEvents();
    this.emptyFrameChangeSubscription = this.emptyTenFrameChangeEvents();
    this.initiateReduceSizeEvents();
    this.setupActiveBackgroundShape();
    this.initiateActiveShapeEvents();
    this.on('dragstart', () => {
      if (!(this.parent instanceof Layer)) {
        this.detach(this);
      }
      this.setPosition(this.getLayer()?.getStage().getPointerPosition());
      this.getLayer()?.batchDraw();
      informationEmitter.emit({
        key: InformationEmitterKeys.ReOrderShapes,
        shape: this,
        uniqueName: this.getUniqueName()
      });
      this.forceDetachSelfFromStack();
    });

    this.dragBoundFunc((pos) => {
      if (pos.x <= 10) {
        pos.x = 10;
      }

      if (pos.y <= 10) {
        pos.y = 10;
      }

      const stage = this.getLayer()?.getStage();
      if (!!stage) {
        const maxXValue = stage.width() - (EmptyTenFrames.FrameDimension(this.tileDimension, this.baseUnit, 'width') / 2);
        const maxYValue = stage.height() - (EmptyTenFrames.FrameDimension(this.tileDimension, this.baseUnit, 'height') / 2) - 85;
        if (pos.x >= maxXValue) {
          pos.x = maxXValue;
        }

        if (pos.y >= maxYValue) {
          pos.y = maxYValue;
        }
      }
      return pos;
    });
  }

  /**
   * Handles various tile events needed by this shape
   * @protected
   */
  protected tileChangeEvents(): Subscription {
    const allowedEventKeys: InformationEmitterKeys[] = [InformationEmitterKeys.TileRemoved];
    return informationEmitter.asObservable()
      .pipe(filter(result => allowedEventKeys.includes(result.key)))
      .subscribe(result => {
        const section = this.getTileSection(result.shape as Tile);
        if (section) {
          section.tile = null;
        }
      });
  }

  /**
   * Handles various tile events needed by this shape
   * @protected
   */
  protected yellowFrameChangeEvents(): Subscription {
    const allowedEventKeys: InformationEmitterKeys[] = [InformationEmitterKeys.YellowFrameRemoved];
    return informationEmitter.asObservable()
      .pipe(filter(result => allowedEventKeys.includes(result.key)))
      .subscribe(result => {
        if (this.isAttachedToYellowFrame()) {
          this.yellowTenFrame = this.yellowTenFrame?.getUniqueName() === result.shape?.getUniqueName() ? null : this.yellowTenFrame;
        }
      });
  }

  protected getTileSection(tile: Tile): ColumnFrame | false {
    for (const rowFrame of this.tileSectionConfig) {
      for (const columnFrame of rowFrame.columns) {
        if (columnFrame.tile?.getUniqueName() === tile.getUniqueName()) {
          return columnFrame;
        }
      }
    }
    return false;
  }

  /**
   * Fills all empty sections with tile
   */
  public fillAllEmptySections(): void {
    for (const rowFrame of this.tileSectionConfig) {
      for (const columnFrame of rowFrame.columns) {
        if (!columnFrame.tile) {
          const tile = new Tile(this.tileDimension, this.baseUnit);
          tile.enableShadow(false);
          this.attachTileToTileGroup(tile, columnFrame);
        }
      }
    }
  }

  /**
   * Returns unique name of object
   */
  public getUniqueName(): string {
    return this.uniqueName;
  }

  /**
   * Setups the base frames
   * @protected
   */
  protected setupFrameSection(): void {
    let offsetRow = (this.baseUnit * EmptyTenFrames.ColumnSpaceMultiplier) / 6;
    for (let row = 1; row <= 5; row++) {

      const rowFrame: RowFrame = {
        row,
        columns: []
      };

      let offsetColumn = (this.baseUnit * EmptyTenFrames.RowBaseSpaceMultiplier) / 3;
      for (let column = 1; column <= 2; column++) {

        const tenFrameGroup = new TenFrameTileGroup(this.tileDimension, this.baseUnit);
        rowFrame.columns.push({ group: tenFrameGroup, tile: null });
        tenFrameGroup.getShape().x(offsetRow);
        tenFrameGroup.getShape().y(offsetColumn);

        offsetColumn = offsetColumn + this.tileDimension.height + (this.baseUnit * EmptyTenFrames.RowBaseSpaceMultiplier) / 3;

        this.getShape().add(tenFrameGroup.getShape());
      }
      offsetRow = offsetRow + this.tileDimension.width + (this.baseUnit * EmptyTenFrames.ColumnSpaceMultiplier) / 6;

      this.tileSectionConfig.push(rowFrame);
    }
  }

  /**
   * Enable drag on shape
   */
  disableDrag(): void {
    this.getShape().draggable(false);
  }

  /**
   * Disable drag on shape
   */
  enableDrag(): void {
    this.getShape().draggable(true);
  }

  /**
   * Returns the main shape
   */
  getShape(): this {
    return this;
  }

  /**
   * Adds tile to a section
   * @param tile
   */
  public addTile(tile: Tile): void {
    try {
      const sectionPosition = this.getSectionPosition(tile);
      const section = this.tileSectionConfig[sectionPosition.row].columns[sectionPosition.column];
      if (!section.tile) {
        this.attachTileToTileGroup(tile, section);
      } else if (section.tile.canStack()) {
        section.tile.stackItem(tile);
        section.tile.resetStackCount();
      }
    } catch (e) {
      if (e?.message === 'Row and column not found') {
        return;
      }
      throw e;
    }
  }

  /**
   * Attaches tile to ten frame tile group
   * @param tile
   * @param section
   * @private
   */
  private attachTileToTileGroup(tile: Tile, section: ColumnFrame): void {
    tile.setPosition(section.group.getPosition());
    section.tile = tile;
    this.getShape().add(tile);
    tile.moveToTop();
    tile.attach();

    if (!!(window as any).InMultiMode && this.isActive()) {
      this.setActive(true, true);
    }
  }

  /**
   * Registers various events to allow snap to grid functionality
   */
  public initiateSnapToGrid(): void {
    this.getShape().on('dragend', () => this.snapToGrid(this.baseUnit));
  }

  /**
   * Snaps item to grid
   * @param unit
   */
  public snapToGrid(unit: number): void {
    this.getShape().position(snapCalculation(this.getShape().position(), unit));
  }

  /**
   * Returns true if all rows and columns are complete
   */
  public isComplete(): boolean {
    for (const rowFrame of this.tileSectionConfig) {
      for (const columnFrame of rowFrame.columns) {
        if (!columnFrame.tile) {
          return false;
        }

        if (columnFrame.tile.totalStackCount() > 0) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Set the base unit
   * @param baseUnit
   */
  public setBaseUnit(baseUnit: number): void {
    this.baseUnit = baseUnit;
  }

  /**
   * Creates a new copy
   */
  public copy(): EmptyTenFrames {
    const tenFrame = new EmptyTenFrames(this.tileDimension, this.baseUnit, this.containerConfig);
    if (this.isComplete()) {
      tenFrame.fillAllEmptySections();
    } else {
      for (const [sectionIndex, sectionConfig] of this.tileSectionConfig.entries()) {
        for (const [columnIndex, column] of sectionConfig.columns.entries()) {
          if (column.tile) {
            const tile = column.tile.copy() as Tile;
            tile.resetStackCount();
            tile.enableShadow(false);
            tenFrame.attachTileToTileGroup(tile, tenFrame.tileSectionConfig[sectionIndex].columns[columnIndex]);
          }
        }
      }
    }

    if (!!this.yellowTenFrame) {
      const yellowFrame = this.yellowTenFrame.copy() as YellowTenFrame;
      yellowFrame.enableShadow(false);
      tenFrame.attach(yellowFrame);
    }

    if (this.stackedItem) {
      const stackedItem = this.stackedItem.copy() as EmptyTenFrames;
      stackedItem.enableShadow(false);
      if (this.stackedItem.draggable()) {
        stackedItem.enableDrag();
      }
      tenFrame.stackItem(stackedItem);
    }

    return tenFrame;
  }

  /**
   * Enables or disables all tile
   */
  public enableAllTilesDrag(enable = true): void {
    for (const rowFrame of this.tileSectionConfig) {
      for (const columnFrame of rowFrame.columns) {
        if (!!columnFrame.tile) {
          enable ? columnFrame.tile.enableDrag() : columnFrame.tile.disableDrag();
        }
      }
    }
  }

  /**
   * Enable removal of tiles from ten frame
   * @param enable
   */
  public enableRemovalOfTiles(enable = true): void {
    for (const rowFrame of this.tileSectionConfig) {
      for (const columnFrame of rowFrame.columns) {
        if (!!columnFrame.tile) {
          // @todo change based on active element flow
        }
      }
    }
  }

  /**
   * Returns the position of the tile
   * @param tile
   * @protected
   */
  protected getSectionPosition(tile: Tile): { row: number, column: number } {
    const tileRect = tile.intersection();
    const framePosition = this.getPosition();
    tileRect.x = Math.abs(tileRect.x - framePosition.x);
    tileRect.y = Math.abs(tileRect.y - framePosition.y);

    let row = 0;
    for (const rowFrame of this.tileSectionConfig) {
      let column = 0;
      for (const columnFrame of rowFrame.columns) {
        const columnRect = columnFrame.group.intersection();
        columnRect.x = Math.abs(columnRect.x - framePosition.x);
        columnRect.y = Math.abs(columnRect.y - framePosition.y);

        if (checkIntersection(columnRect, tileRect, 0)) {
          return { row, column };
        }
        column++;
      }

      row++;
    }

    throw new SectionConfigNotFound('Row and column not found');
  }

  destroy(): this {
    this.tileChangeSubscription.unsubscribe();
    this.yellowFrameChangeSubscription.unsubscribe();
    this.activeShapeSubscription.unsubscribe();
    informationEmitter.emit({
      key: InformationEmitterKeys.EmptyTenFrameRemoved,
      uniqueName: this.getUniqueName(),
      shape: this
    });
    return super.destroy();
  }

  /**
   * Returns true if a yellow frame can be attached to ten frame
   */
  public canBeAttachedToYellowFrame(): boolean {
    return this.isComplete() && !this.yellowTenFrame;
  }

  /**
   * Attach to a parent
   */
  public attach(shape: YellowTenFrame | EmptyHundredFrame): void | boolean {
    if (shape instanceof YellowTenFrame) {
      this.enableRemovalOfTiles(false);
      this.enableAllTilesDrag(false);
      this.yellowTenFrame = shape;
      this.allowDetachment(true);
      this.add(shape);
      this.setActive(true);
      return true;
    }

    if (shape instanceof EmptyHundredFrame) {
      this.rotateBy90();
      this.emptyHundredFrame = shape;
      return true;
    }

    return false;
  }

  /**
   * Detach to a parent
   */
  public detach(shape: YellowTenFrame | EmptyHundredFrame | this): void {
    informationEmitter.emit({
      key: InformationEmitterKeys.ReOrderShapes,
      shape: this,
      uniqueName: this.getUniqueName()
    });

    if (shape instanceof YellowTenFrame) {
      this.enableRemovalOfTiles(true);
      this.enableAllTilesDrag(true);
      this.yellowTenFrame = null;
      return;
    }

    if (shape instanceof EmptyHundredFrame) {
      this.getShape().moveTo(this.getShape().getLayer());
      informationEmitter.emit({
        key: InformationEmitterKeys.EmptyTenFrameRemoved,
        uniqueName: this.getUniqueName(),
        shape: this
      });
      if (this.isRotated()) {
        this.revertRotation();
      }
      this.emptyHundredFrame = null;
      return;
    }

    if (shape instanceof EmptyTenFrames) {
      this.getShape().moveTo(this.getShape().getLayer());
      informationEmitter.emit({
        key: InformationEmitterKeys.EmptyTenFrameRemoved,
        uniqueName: this.getUniqueName(),
        shape: this
      });
      this.emptyHundredFrame = null;
      if (this.isRotated()) {
        this.revertRotation();
      }
      // this.yellowTenFrame = null;
      return;
    }
  }

  public intersection(): IRect {
    return this.baseRect.getClientRect();
  }

  /**
   * Reduce size according to reduction logic
   */
  public reduceSize(): void {
    this.scale({ x: this.reduceSizeBy(), y: this.reduceSizeBy() });
  }

  /**
   * Initiate events to tackle reduction in size
   */
  public initiateReduceSizeEvents(): void {
    this.getShape().on('dragstart', () => this.reduceSize());
    this.getShape().on('dragend', () => this.revertToOriginalSize());
  }

  /**
   * Reverts back to original size
   */
  public revertToOriginalSize(): void {
    this.scale({ x: 1, y: 1 });
  }

  /**
   * Reduction size multiplier
   * Range is from 0 to 1.
   */
  public reduceSizeBy(): number {
    return 0.5;
  }

  /**
   * Returns true if the shape is rotated by 90 deg
   */
  public isRotated(): boolean {
    return this.rotated;
  }

  /**
   * Rotates the shape by 90 deg
   */
  public rotateBy90(): void {
    this.rotate(90);
    this.offsetY(this.find('.outer-rect')[0].height());
    this.rotated = true;
  }

  /**
   * Reverts the rotation
   */
  public revertRotation(): void {
    this.rotate(270);
    this.offsetY(0);
    this.rotated = false;
  }

  /**
   * Returns true if attached to yellow frame
   */
  public isAttachedToYellowFrame(): boolean {
    return !!this.yellowTenFrame;
  }

  public initiateActiveShapeEvents(): void {
    const handler = (event: KonvaEventObject<(MouseEvent | TouchEvent)> & { type: 'dragstart' | string }, inDrag = false) => {

      if (!this.canBeActive()) {
        return;
      }

      if (!!this.emptyHundredFrame) {
        return;
      }

      const inMultiMode = (window as any).InMultiMode;

      if (inMultiMode) {
        let parentElement = this.parentStack;
        while (parentElement) {
          if (!!(parentElement as EmptyTenFrames).emptyHundredFrame) {
            return;
          }
          parentElement = parentElement.parentStack;
        }

      }

      const canActivateTile = (inMultiMode && !this.isActive()) || !inMultiMode;

      const shape = event.target;

      if (shape instanceof Tile && canActivateTile) {
        shape.setActive(true);
        this.setActive(false);
        return;
      }

      if (shape.parent instanceof Tile && canActivateTile) {
        shape.parent.setActive(true);
        this.setActive(false);
        return;
      }

      if ((shape instanceof Rect || shape instanceof Circle) && canActivateTile) {
        if (shape.parent instanceof Tile) {
          shape.parent.setActive(true);
          this.setActive(false);
          return;
        }

        if (shape.parent?.parent instanceof Tile) {
          shape.parent.parent.setActive(true);
          this.setActive(false);
          return;
        }
      }

      if (shape instanceof Text && canActivateTile) {
        if (shape.parent instanceof Tile) {
          shape.parent.setActive(true);
          this.setActive(false);
          return;
        }
      }

      this.setActive(true, inDrag);
    };

    this.on('dragstart', (event) => handler(event as any, true));
    this.on('click tap', (event) => handler(event as any));
    this.activeShapeSubscription = informationEmitter.asObservable()
      .pipe(filter(event => event.key === InformationEmitterKeys.ActiveShapeChanged))
      .pipe(filter(event => event.uniqueName !== this.getUniqueName()))
      .pipe(filter(() => ((window as any).InMultiMode !== true)))
      .pipe(filter(() => this.isActive()))
      .subscribe(() => this.setActive(false));
  }

  /**
   * Returns true if shape is removable
   */
  public canBeRemoved(): boolean {
    return this.activeShapeState.removable;
  }

  /**
   * Returns true if shape is detachable
   */
  public canBeDetached(): boolean {
    return this.activeShapeState.detachable;
  }

  /**
   * Sets the removable state of shape
   * @param allowRemoval
   */
  public allowRemoval(allowRemoval = true): void {
    this.activeShapeState.removable = allowRemoval;
  }

  /**
   * Sets the detachable state of shape
   * @param allowDetachment
   */
  public allowDetachment(allowDetachment = true): void {
    this.activeShapeState.detachable = allowDetachment;
  }

  /**
   * Returns true if current shape is active
   */
  public isActive(): boolean {
    return this.activeShapeState.isActive;
  }

  /**
   * Setup active background shape for current shape
   */
  public setupActiveBackgroundShape(): void {
    this.activeBackground = new ActiveBackground(this.baseUnit);
    this.activeBackground.add(new Rect({
      height: this.baseRect.height(),
      width: this.baseRect.width(),
      strokeWidth: 0.5 * this.baseUnit,
      fill: 'rgba(128, 128, 128, 0.4)',
      stroke: 'rgba(128, 128, 128)',
    }));
    this.activeBackground.hide();
    this.add(this.activeBackground);
  }

  /**
   * Sets the shape as active/inactive
   * @param active
   * @param inDrag
   */
  public setActive(active = true, inDrag = false): void {
    const wasActive = this.isActive();

    if (!this.canBeActive()) {
      active = false;
    }

    if (this.isActive() && (window as any).InMultiMode && !inDrag) {
      active = false;
    }

    this.activeShapeState.isActive = active;
    this.isActive() ? this.getActiveBackground().show() : this.getActiveBackground().hide();
    this.activeBackground.moveToBottom();
    this.activeBackground.moveUp();
    if (this.isActive()) {
      if (!!this.yellowTenFrame) {
        this.activeBackground.moveToTop();
      }
      informationEmitter.emit({
        key: InformationEmitterKeys.ActiveShapeChanged,
        shape: this,
        uniqueName: this.getUniqueName()
      });
    }

    if (wasActive && !this.isActive()) {
      informationEmitter.emit({
        key: InformationEmitterKeys.ActiveShapeRemoved,
        shape: this,
        uniqueName: this.getUniqueName()
      });
    }

    if ((window as any).InMultiMode && this.isActive()) {
      this.setTileActiveStateToFalse();
    }

    if ((window as any).InMultiMode) {
      const isActive = this.isActive();
      let parentStack = this.parentStack as (EmptyTenFrames | null);
      while (parentStack) {
        parentStack.setActive(isActive, isActive ? true : inDrag);
        parentStack = parentStack.parentStack as (EmptyTenFrames | null);
      }
    }

  }

  /**
   * Returns active background shape that maintains visual state of active shape
   */
  public getActiveBackground(): BaseShape {
    return this.activeBackground;
  }

  /**
   * Triggers detach action (detaches linked shape from another shape)
   */
  public triggerDetach(): void {
    if (!!this.yellowTenFrame) {
      this.yellowTenFrame.destroy();
      this.yellowTenFrame = null;
      this.allowDetachment(false);
      this.enableAllTilesActivation();
      this.enableRemovalOfTiles();
      this.enableAllTilesDrag();
      this.getLayer()?.batchDraw();
    }
  }

  /**
   * Triggers remove action (equal to trash)
   */
  public triggerRemove(): void {
    this.destroy();
  }

  /**
   * Allows the shape to be tackle active state handling
   * @param allow
   * @protected
   */
  public allowActivation(allow: boolean): void {
    this.activeShapeState.canBeActivated = allow;
    if (this.isActive() && !this.activeShapeState.canBeActivated) {
      this.setActive(false);
    }
  }

  /**
   * Returns true if the shape can be activated
   */
  public canBeActive(): boolean {
    return this.activeShapeState.canBeActivated;
  }

  /**
   * Enables/Disables active state of tiles
   * @param enable
   */
  public enableAllTilesActivation(enable = true): void {
    for (const row of this.tileSectionConfig) {
      for (const column of row.columns) {
        if (!!column.tile) {
          column.tile.allowActivation(enable);
        }
      }
    }
  }

  /**
   * Enable/Disable active state of yellow ten frame
   * @param enable
   */
  public enableYellowFrameActivation(enable = true): void {
    if (!!this.yellowTenFrame) {
      this.yellowTenFrame.allowActivation(enable);
    }
  }

  public enableShadow(enable = true): void {
    this.baseRect.shadowEnabled(enable);
  }

  /**
   * Returns true when stacking is allowed
   */
  public canStack(): boolean {
    return this.isAttachedToYellowFrame();
  }

  /**
   * Set stacked item
   * @param item
   */
  public stackItem(item: BaseShape & Stackable): void {
    if (!!this.stackedItem) {
      this.stackedItem.stackItem(item);
      return;
    }
    this.stackedItem = item;
    this.stackedItem.markSelfStacked(this.stackCount);
    this.stackedItem.setParentStack(this);
    this.add(item);
    item.moveToTop();
    this.getLayer()?.batchDraw();
    this.disableDrag();

    if ((window as any).InMultiMode) {
      this.setActive(true, true);
      (item as Tile).setActive(true, true);
    }
  }

  /**
   * Remove stacked item
   */
  removeStackItem(): void {
    this.stackedItem?.removeSelfStacked();
    this.stackedItem?.setParentStack(null);
    this.stackedItem = null;
    this.enableDrag();
  }

  /**
   * Mark self object as stacked to parent
   * @param parentCount
   */
  public markSelfStacked(parentCount: number): void {
    this.stackCount = parentCount;
    this.stackCount++;
    this.position({ x: 0, y: 0 });
    this.allowDetachment(false);
    if (!!this.yellowTenFrame) {
      this.yellowTenFrame.allowDetachment(false);
    }
    this.showCount(true);
  }

  /**
   * Hide show counter of stack
   * @param show
   */
  showCount(show: boolean): void {
    this.yellowTenFrame?.showCircle(true);
    this.text.hide();
    if (show) {
      this.yellowTenFrame?.showCircle(false);
      this.text.show();
      this.text.text((this.totalStackCount() + 1).toString());
      this.text.moveToTop();
    }
  }

  /**
   * Remove self from parent stack
   */
  public removeSelfStacked(): void {
    this.stackCount = 0;
    if (!(this.parent instanceof Layer)) {
      this.getShape().moveTo(this.getShape().getLayer());
    }
    if (!!this.yellowTenFrame) {
      this.allowDetachment(true);
      this.yellowTenFrame.allowDetachment(true);
    }
    this.showCount(false);
  }

  /**
   * Set current parent stack object
   * @param item
   */
  public setParentStack(item: BaseShape & Stackable | null): void {
    this.parentStack = item;
  }

  /**
   * Detach from parent stack force fully
   */
  public forceDetachSelfFromStack(): void {
    this.parentStack?.removeStackItem();
  }

  /**
   * Returns total stack count
   */
  public totalStackCount(): number {
    if (!!this.stackedItem) {
      return this.stackedItem.totalStackCount();
    }

    return this.stackCount;
  }

  protected emptyTenFrameChangeEvents(): Subscription {
    return informationEmitter.asObservable()
      .pipe(filter(event => event.key === InformationEmitterKeys.EmptyTenFrameRemoved))
      .subscribe(event => {
        if (this.stackedItem?.getUniqueName() === event.uniqueName) {
          this.removeStackItem();
        }
      });
  }

  public setTileActiveStateToFalse(): void {
    this.tileSectionConfig.forEach(tileSection => tileSection.columns.forEach(column => {
      if (!!column.tile) {
        column.tile.setActive(false);
        let stackedItem = column.tile.stackedItem;

        while (stackedItem !== null) {
          (stackedItem as Tile).setActive(false);
          stackedItem = stackedItem.stackedItem;
        }
      }
    }));
  }
}


export class SectionConfigNotFound extends Error {
}
