import Konva from 'konva';
import Vector2d = Konva.Vector2d;
import { IRect } from 'konva/types/types';
import Group = Konva.Group;
import Shape = Konva.Shape;

export const snapCalculation =
  (position: Vector2d, unit: number): Vector2d => ({
    x: Math.round(position.x / unit) * unit,
    y: Math.round(position.y / unit) * unit,

  });

/**
 * Checks intersection against reference bound checking if intersecting bound is larger than the intersecting bound
 *
 *
 * @param referenceBound
 * @param intersectingBound
 * @param margin
 */
export const checkIntersection = (referenceBound: IRect, intersectingBound: IRect, margin = 0): boolean => {
  return !(
    (intersectingBound.x * (1 - margin / 100)) > referenceBound.x + referenceBound.width ||
    intersectingBound.x + intersectingBound.width < (referenceBound.x * (1 + margin / 100)) ||
    (intersectingBound.y * (1 - margin / 100)) > referenceBound.y + referenceBound.height ||
    intersectingBound.y + intersectingBound.height < (referenceBound.y * (1 + margin / 100))
  );
};

export const generateUniqueName = (): string => {
  return Math.random().toString(36).substr(2, 9);
};

