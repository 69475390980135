import { Injectable } from '@angular/core';
import Konva from 'konva';
import { LayerService } from '../layer/layer.service';
import { EmptyTenFrames } from '../../shapes/empty-ten-frames/empty-ten-frames';
import { EmptyHundredFrame } from '../../shapes/empty-hundred-frame/empty-hundred-frame';
import { informationEmitter, InformationEmitterKeys } from '../../utils/information-emitter';
import Layer = Konva.Layer;
import Line = Konva.Line;
import Vector2d = Konva.Vector2d;
import { SelectingShape } from '../../shapes/selecting-shape/selecting-shape';
import { Tile } from '../../shapes/tile/tile';
import { BlueHundredFrame } from '../../shapes/blue-hundred-frame/blue-hundred-frame';
import { YellowTenFrame } from '../../shapes/yellow-ten-frame/yellow-ten-frame';
import { timer } from 'rxjs';
import Stage = Konva.Stage;

@Injectable({
  providedIn: 'root'
})
export class FreeHandDrawService {

  protected layer: Layer;

  public canDraw = false;

  public canErase = false;

  protected isPainting = false;

  constructor(
    public layerService: LayerService
  ) {
    this.layer = new Layer();
    this.layer.addName('freeHand');
  }

  /**
   * Enable drawing
   * @param enable
   */
  public enableDraw(enable = true): void {
    this.canDraw = enable;
    if (enable) {
      this.enableEraser(false);
    }
    this.enableDraggingOnMainLayer(!enable);
  }

  public getLayer(): Layer {
    return this.layer;
  }

  /**
   * Enable erasing
   * @param enable
   */
  public enableEraser(enable = true): void {
    this.canErase = enable;
    if (enable) {
      this.enableDraw(false);
    }
    this.enableDraggingOnMainLayer(!enable);
  }

  public registerDrawEvents(): void {
    let lastLine: Line;
    this.layer.getStage().on('mousedown touchstart', (e) => {
      if (this.canDraw || this.canErase) {
        this.isPainting = true;
        const pos = this.layer.getStage().getPointerPosition() as Vector2d;
        lastLine = new Line({
          stroke: '#000000',
          strokeWidth: this.canDraw ? 5 : 20,
          globalCompositeOperation:
            this.canDraw ? 'source-over' : 'destination-out',
          points: [pos.x, pos.y],
          name: 'draw'
        });
        this.layer.add(lastLine);
      }
    });

    this.layer.getStage().on('mouseup touchend', () => {
      this.isPainting = false;
    });

    // and core function - drawing
    this.layer.getStage().on('mousemove touchmove', () => {
      if (this.canDraw || this.canErase) {
        if (!this.isPainting) {
          return;
        }

        const pos = this.layer.getStage().getPointerPosition() as Vector2d;
        const newPoints = lastLine.points().concat([pos.x, pos.y]);
        lastLine.points(newPoints);
        this.layer.batchDraw();
      }
    });
  }

  public enableDraggingOnMainLayer(enable = true): void {
    for (const child of this.layerService.getLayer().children) {
      enable ? child.draggable(true) : child.draggable(false);

      if (child instanceof EmptyTenFrames) {
        if (!child.yellowTenFrame) {
          child.enableAllTilesDrag(enable);
        }
      }

      if (child instanceof EmptyHundredFrame) {
        if (!child.blueFrame) {
          child.enableAllTenFrameDrag(enable);
        }
      }
    }

    this.layerService.getLayer().find('.skip-drag').each(child => child.draggable(false));
  }

  /**
   * Checks if system is still in draw state or not and emits various events
   */
  public checkDrawStatus(): void {
    let key: InformationEmitterKeys = InformationEmitterKeys.DrawModeDeActivated;
    if (this.isInDrawState()) {
      key = InformationEmitterKeys.DrawModeActivated;
    }

    informationEmitter.emit({
      uniqueName: 'will-not-match',
      key,
      shape: null,
    });
  }

  /**
   * Returns true if system is in draw state
   */
  public isInDrawState(): boolean {
    return this.canDraw || this.canErase;
  }

  /**
   * Clears all lines drawn
   */
  public clearAll(): void {
    this.layer.removeChildren();
    this.layer.batchDraw();
  }

  /**
   * When in multi mode creates a selecting rectangle and maps
   * @param stage
   * @param layer
   */
  public initSelectionRectangle(stage: Stage, layer: Layer): void {
    const selectionRectangle = new SelectingShape();
    this.getLayer().add(selectionRectangle);
    let x1: number;
    let x2: number;
    let y1: number;
    let y2: number;
    stage.on('mousedown touchstart', (event) => {
      if (!(event.target instanceof Stage)) {
        if (event.target.getLayer()?.name() !== this.getLayer().name()) {
          return;
        }
      }

      const pointerPosition = stage.getPointerPosition() as Vector2d;
      x1 = pointerPosition.x;
      y1 = pointerPosition.y;
      x2 = pointerPosition.x;
      y2 = pointerPosition.y;

      if ((window as any).InMultiMode && !this.isInDrawState()) {
        selectionRectangle.visible(true);
        selectionRectangle.moveToTop();
      }
      selectionRectangle.width(0);
      selectionRectangle.height(0);
      this.getLayer().batchDraw();
    });

    stage.on('mousemove touchmove', () => {
      // do nothing if we didn't start selection
      if (!selectionRectangle.visible()) {
        return;
      }

      const pointerPosition = stage.getPointerPosition() as Vector2d;
      x2 = pointerPosition.x;
      y2 = pointerPosition.y;

      selectionRectangle.setAttrs({
        x: Math.min(x1, x2),
        y: Math.min(y1, y2),
        width: Math.abs(x2 - x1),
        height: Math.abs(y2 - y1),
      });
      this.getLayer().batchDraw();
    });

    stage.on('mouseup touchend', () => {
      // do nothing if we didn't start selection
      if (!selectionRectangle.visible()) {
        return;
      }
      // update visibility in timeout, so we can check it in click event
      setTimeout(() => {
        selectionRectangle.visible(false);
      });


      const box = selectionRectangle.getClientRect();
      const selected = layer.getChildren(((shape) =>
          Konva.Util.haveIntersection(box, shape.getClientRect())
      ));

      selected.each(shape => {
        if (shape instanceof SelectingShape) {
          return;
        }

        if (
          shape instanceof Tile ||
          shape instanceof EmptyTenFrames ||
          shape instanceof BlueHundredFrame ||
          shape instanceof EmptyHundredFrame ||
          shape instanceof YellowTenFrame
        ) {
          shape.setActive(true, true);
        }
      });
      stage.batchDraw();
      timer(10).subscribe(() => stage.batchDraw());
    });
  }
}
