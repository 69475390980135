import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  faSearchPlus,
  faSearchMinus,
  faUndoAlt,
  faEyeSlash,
  faEye,
  faTrashAlt,
  faLink,
  faGlobeAmericas,
  faEnvelope,
  faShoppingCart,
  faPencilAlt,
  faEraser,
  faInfo,
  faSync, faSquare, faTh, faCopy
} from '@fortawesome/free-solid-svg-icons';

import { AppComponent } from './app.component';
import { ActiveElementHandlerComponent } from './components/active-element-handler/active-element-handler.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { TextContentComponent } from './components/modals/text-content/text-content.component';
import { FormsModule } from '@angular/forms';
import { InfoModalComponent } from './components/modals/info-modal/info-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    ActiveElementHandlerComponent,
    TextContentComponent,
    InfoModalComponent
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faSearchPlus,
      faSearchMinus,
      faUndoAlt,
      faEyeSlash,
      faEye,
      faTrashAlt,
      faLink,
      faGlobeAmericas,
      faEnvelope,
      faShoppingCart,
      faPencilAlt,
      faEraser,
      faInfo,
      faSync,
      faSquare,
      faTh,
      faCopy
    );
  }
}
