import { EventEmitter } from '@angular/core';
import { BaseShape } from '../interfaces/shape-actions';

export enum InformationEmitterKeys {
  TileRemoved = 'tile-removed',
  ReOrderShapes = 're-order-shapes',
  EmptyTenFrameRemoved = 'empty-ten-frame-removed',
  YellowFrameRemoved = 'yellow-frame-removed',
  EmptyHundredFrameRemoved = 'empty-hundred-frame-removed',
  BlueFrameRemoved = 'blue-frame-removed',
  ActiveShapeChanged = 'active-shape-changed',
  ActiveShapeRemoved = 'active-shape-removed',
  DrawModeActivated = 'draw-mode-activated',
  DrawModeDeActivated = 'draw-mode-deactivated',
  LayerClicked = 'layer-clicked',
  ZoomIn = 'zoom-in',
  ZoomOut = 'zoom-out',
  InMultiMode = 'in-multi-mode',
  NotInMultiMode = 'not-in-multi-mode',
}

export interface InformationEmitter {
  key: InformationEmitterKeys;
  uniqueName: string;
  shape: BaseShape | null;
}

export const informationEmitter: EventEmitter<InformationEmitter> = new EventEmitter<InformationEmitter>();
