<div class="parent-container" #parentContainer>
  <div class="actions action-options">
    <div class="system-actions">
      <button class="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Information"
              (click)="showInformationModal()">
        <fa-icon icon="info"></fa-icon>
      </button>
      <button class="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Show/Hide Menu"
              (click)="showMenu()">
        <fa-icon [icon]="menuShow()"></fa-icon>
      </button>
      <button class="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Text Box" (click)="addText()">T
      </button>
      <button class="btn btn-primary"
              data-placement="top" data-toggle="tooltip" title="Pencil"
              [class.text-dark]="freeHandDrawService.canDraw"
              (click)="freeHandDrawService.enableDraw(!freeHandDrawService.canDraw); freeHandDrawService.checkDrawStatus()">
        <fa-icon icon="pencil-alt"></fa-icon>
      </button>
      <button class="btn btn-primary"
              data-placement="top" data-toggle="tooltip" title="Eraser"
              [class.text-dark]="freeHandDrawService.canErase"
              (click)="freeHandDrawService.enableEraser(!freeHandDrawService.canErase); freeHandDrawService.checkDrawStatus()">
        <fa-icon icon="eraser"></fa-icon>
      </button>
      <button class="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Zoom In"
              (click)="layerService.zoomIn()">
        <fa-icon icon="search-plus"></fa-icon>
      </button>
      <button class="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Zoom Out"
              (click)="layerService.zoomOut()">
        <fa-icon icon="search-minus"></fa-icon>
      </button>
      <button class="btn btn-primary"
              data-placement="top" data-toggle="tooltip" title="Clear"
              (click)="layerService.resetZoom(); freeHandDrawService.clearAll(); layerService.clearAll();">
        <fa-icon icon="sync"></fa-icon>
      </button>
      <button class="btn btn-primary"
              data-placement="top" data-toggle="tooltip" title="Copy & Paste"
              (click)="copyShape($any(this.inMultiMode ? this.activeShapesInMultiMode : [this.activeShape]))">
        <fa-icon icon="copy"></fa-icon>
      </button>
      <button class="btn btn-primary"
              [class.text-dark]="!inMultiMode"
              data-placement="top" data-toggle="tooltip" title="Single-select"
              (click)="enableMultiMode()">
        <fa-icon [icon]="$any('square')"></fa-icon>
      </button>
      <button class="btn btn-primary"
              data-placement="top" data-toggle="tooltip" title="Multi-select"
              [class.text-dark]="inMultiMode"
              (click)="enableMultiMode()">
        <fa-icon [icon]="$any('th')"></fa-icon>
      </button>
      <ng-container *ngIf="!inMultiMode; else inMultiModeTemplate">
        <button class="btn btn-danger"
                data-placement="top" data-toggle="tooltip" title="Delete"
                (click)="remove()" *ngIf="activeShape?.canBeRemoved()">
          <fa-icon icon="trash-alt"></fa-icon>
        </button>
        <button class="btn btn-warning"
                data-placement="top" data-toggle="tooltip" title="Ungroup"
                (click)="detach()" *ngIf="activeShape?.canBeDetached()">
          <fa-icon class="text-white" icon="link"></fa-icon>
        </button>
        <img [src]="shapeImage" *ngIf="activeShape" alt="" class="active-shape-img">

      </ng-container>
      <ng-template #inMultiModeTemplate>
        <button class="btn btn-danger"
                data-placement="top" data-toggle="tooltip" title="Delete"
                (click)="removeMultiModeShapes()">
          <fa-icon icon="trash-alt"></fa-icon>
        </button>
      </ng-template>

    </div>
    <div class="links">
      <ul class="list-unstyled">
        <li><a href="https://kpmathematics.com" target="_blank">
          <fa-icon icon="globe-americas" class="globe-color"></fa-icon>
          <span class="d-none d-sm-inline">Website</span>
        </a></li>
        <li><a href="https://kpmathematics.com/shop/" target="_blank">
          <fa-icon icon="shopping-cart" class="cart-color"></fa-icon>
          <span class="d-none d-sm-inline">Store</span>
        </a></li>
        <li><a href="https://kpmathematics.com/contact/" target="_blank">
          <fa-icon icon="envelope" class="mail-color"></fa-icon>
          <span class="d-none d-sm-inline">Contact Us</span>
        </a></li>
        <li><img src="/assets/footer-img.png" alt="Footer Image" class="footer-logo"></li>
      </ul>
    </div>
  </div>
  <div id="play-stage" #stage
       [class.eraser-mode]="freeHandDrawService.canErase"
       [class.pencil-mode]="freeHandDrawService.canDraw"
  ></div>
</div>
