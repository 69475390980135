<h2 mat-dialog-title class="text-center">
  KP Ten-Frame Tiles App - Tips and Suggestions
  <button type="button" class="close" (click)="dialogRef.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</h2>
<mat-dialog-content>
  <p><b>Learn More:</b>To learn more about how KP Ten-Frame Tiles, including this app, demonstrate the nesting nature of
    place value,
    <a href="/assets/app-intro.pdf" target="_blank">click here</a>.
  </p>
  <p><b>Covering Filled Ten Frames:</b> If you have trouble getting a grouping tile to snap into place, try this simple
    trick: place the left-hand side of the grouping tile in the center of where you want it to snap. It doesn’t matter
    where your cursor is pointing. Simply pay attention to the left-hand side of the grouping tile. </p>

  <p>To <b>highlight</b> an item in the workspace, simply click on it.
  </p>

  <div class="information-list fix-margin">
    <div class="information-img">
      <img src="/assets/help-modal-images/information.png" alt="Information">
    </div>
    <div>
      <span> <b>Information:</b>  links to “Tips and Suggestions”</span>
    </div>
  </div>

  <div class="information-list">
    <div>
      <img src="/assets/help-modal-images/hide-show.png" alt="Hide Show">
    </div>
    <div>
      <span> <b>Hide Sidebar:</b> makes the workspace larger by hiding the sidebar</span>
    </div>
  </div>

  <div class="information-list">
    <div><img src="/assets/help-modal-images/text.png" alt="Text"></div>
    <div>
      <p><b>Text Box:</b> types in a textbox</p>
    </div>
  </div>

  <div class="information-list">
    <div>
      <img src="/assets/help-modal-images/pencil.png" alt="write">
    </div>
    <div>
      <p><b>Write:</b> writes with a pencil (click on this button again when you are finished writing)

      </p>
    </div>
  </div>

  <div class="information-list">
    <div><img src="/assets/help-modal-images/eraser.png" alt="Eraser"></div>
    <div>
      <p><b>Erase Writing: </b>erases your pencil marks (click on this button again when you are finished erasing)
      </p>
    </div>
  </div>

  <div class="information-list">
    <div><img src="/assets/help-modal-images/zoom-in.png" alt="Zoom-in"></div>
    <div>
      <p><b>Zoom In:</b> makes images larger
      </p>
    </div>
  </div>

  <div class="information-list">
    <div><img src="/assets/help-modal-images/zoom-out.png" alt="Zoom-out"></div>
    <div>
      <p><b>Zoom Out:</b> makes images smaller

      </p></div>
  </div>

  <div class="information-list">
    <div><img src="/assets/help-modal-images/refresh.png" alt="refresh"></div>
    <div><p><b>Clear:</b> clears entire screen</p></div>
  </div>

  <div class="information-list">
    <div><img src="/assets/help-modal-images/copy-paste.png" alt="copy-paste"></div>
    <div><p><b>Copy &amp; Paste:</b> copies and pastes highlighted images</p></div>
  </div>

  <div class="information-list">
    <div><img src="/assets/help-modal-images/single-select.png" alt="single-select"></div>
    <div><p><b>Single-select:</b> highlights one image at a time</p></div>
  </div>

  <div class="information-list">
    <div><img src="/assets/help-modal-images/multi-select.png" alt="multi-select"></div>
    <div><p><b>Multi-select:</b> highlights all selected images</p></div>
  </div>

  <div class="information-list">
    <div><img src="/assets/help-modal-images/delete.png" alt="delete"></div>
    <div><p><b>Delete: </b>removes all highlighted images
    </p>
    </div>
  </div>

  <div class="information-list">
    <div><img src="/assets/help-modal-images/unlink.png" alt="unlink"></div>
    <div><p><b>Ungroup:</b> removes the yellow or blue grouping tile(s)

    </p></div>
  </div>

</mat-dialog-content>
