export enum ShapeNames {
  Tile = 'tile',
  EmptyTenFrame = 'emptyTenFrame',
  TenFrameTileGroup = 'tenFrameTileGroup',
  Grid = 'grid',
  AttachedTile = 'attachedTile',
  GridSlot = 'gridSlot',
  LeftMenu = 'leftMenu',
  EmptyHundredFrame = 'emptyHundredFrame',
  YellowTenFrame = 'emptyHundredFrame',
}
