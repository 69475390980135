import Konva from 'konva';
import Group = Konva.Group;
import Rect = Konva.Rect;
import {
  ActiveShape, ActiveShapeState,
  BaseShape, ReduceSizeOnDrag,
  ShapeIsDraggable,
  ShapeToParentActions,
  SnapToGrid
} from '../../interfaces/shape-actions';
import { DimensionScale } from '../../interfaces/dimension-scale';
import ContainerConfig = Konva.ContainerConfig;
import { checkIntersection, generateUniqueName, snapCalculation } from '../../utils/helpers';
import { YellowTenFrame } from '../yellow-ten-frame/yellow-ten-frame';
import { EmptyTenFrames } from '../empty-ten-frames/empty-ten-frames';
import { HundredFrameTenGroup } from '../hundred-frame-ten-group/hundred-frame-ten-group';
import { Subscription } from 'rxjs';
import { informationEmitter, InformationEmitterKeys } from '../../utils/information-emitter';
import { filter, tap } from 'rxjs/operators';
import { Tile } from '../tile/tile';
import { BlueHundredFrame } from '../blue-hundred-frame/blue-hundred-frame';
import { IRect } from 'konva/types/types';
import { ActiveBackground } from '../active-background/active-background';
import Circle = Konva.Circle;
import KonvaEventObject = Konva.KonvaEventObject;
import Text = Konva.Text;

interface RowFrame {
  columns: ColumnFrame[];
}

interface ColumnFrame {
  tenFrame: EmptyTenFrames | null;
  group: HundredFrameTenGroup;
}

export class EmptyHundredFrame extends Group implements BaseShape,
  ShapeIsDraggable,
  SnapToGrid,
  ReduceSizeOnDrag,
  ShapeToParentActions,
  ActiveShape {

  /**
   * Active shape state
   */
  public activeShapeState: ActiveShapeState = {
    detachable: false,
    removable: true,
    isActive: false,
    canBeActivated: true
  };

  // @ts-ignore
  private activeShapeSubscription: Subscription;

  // @ts-ignore
  protected activeBackground: ActiveBackground;

  /**
   * Unique name of object
   * @protected
   */
  protected uniqueName: string;

  /**
   * Ten frame section
   * @protected
   */
  protected tenFrameSection: RowFrame[] = [];

  /**
   * Information emitter subscription
   * @private
   */
  private tenFrameChangeSubscription: Subscription;

  /**
   * Base rect
   * @private
   */
  private baseRect: Rect;

  /**
   * Attached blue frame
   * @protected
   */
  public blueFrame: BlueHundredFrame | null = null;

  /**
   * Subscription of change of blue hundred frame
   *
   * @protected
   */
  protected blueFrameChangeSubscription: Subscription;

  public countManager: number;

  public static StrokeWidth(baseUnit: number): number {
    return 0.25 * baseUnit;
  }

  public static FrameDimension(tileDimension: DimensionScale, baseUnit: number, forType: 'width' | 'height'): number {
    const multiplier = forType === 'height' ? 5 : 2;
    const outsideMargin = 10 * baseUnit;
    return multiplier * YellowTenFrame.FrameDimension(tileDimension, baseUnit, forType) + outsideMargin;
  }

  constructor(protected tileDimension: DimensionScale, public baseUnit: number, protected containerConfig?: ContainerConfig) {
    super(containerConfig);
    this.countManager = (window as any).CounterManager;
    (window as any).CounterManager++;
    this.uniqueName = generateUniqueName();
    this.baseRect = new Rect({
      x: 0,
      y: 0,
      width: EmptyHundredFrame.FrameDimension(tileDimension, baseUnit, 'height'),
      height: EmptyHundredFrame.FrameDimension(tileDimension, baseUnit, 'width'),
      strokeWidth: 2 * EmptyHundredFrame.StrokeWidth(baseUnit),
      stroke: 'black',
      fill: 'white',
      shadowEnabled: true,
      shadowOpacity: 0.4,
      shadowOffset: { x: 5, y: 6 },
      shadowBlur: 5
    });
    this.add(this.baseRect);
    this.initiateSnapToGrid();
    this.setupSectionConfig();
    this.initiateReduceSizeEvents();
    this.setupActiveBackgroundShape();
    this.initiateActiveShapeEvents();

    this.on('click', () => console.log(this));

    this.on('dragstart', () => informationEmitter.emit({
      key: InformationEmitterKeys.ReOrderShapes,
      shape: this,
      uniqueName: this.getUniqueName()
    }));
    this.tenFrameChangeSubscription = this.tenFrameChangeEvents();
    this.dragBoundFunc((pos) => {
      if (pos.x <= 10) {
        pos.x = 10;
      }

      if (pos.y <= 10) {
        pos.y = 10;
      }

      const stage = this.getLayer()?.getStage();
      if (!!stage) {
        const maxXValue = stage.width() - (EmptyHundredFrame.FrameDimension(tileDimension, baseUnit, 'width') / 2);
        const maxYValue = stage.height() - (EmptyHundredFrame.FrameDimension(tileDimension, baseUnit, 'height') / 2) - 50;
        if (pos.x >= maxXValue) {
          pos.x = maxXValue;
        }

        if (pos.y >= maxYValue) {
          pos.y = maxYValue;
        }
      }
      return pos;
    });

    this.blueFrameChangeSubscription = this.blueFrameChangeEvents();
  }

  /**
   * Copy shape
   */
  public copy(): EmptyHundredFrame {
    const copiedHundredFrame = new EmptyHundredFrame(this.tileDimension, this.baseUnit, this.containerConfig);

    if (this.isComplete()) {
      copiedHundredFrame.fillAllEmptySections();
    } else {
      for (const [sectionIndex, sectionConfig] of this.tenFrameSection.entries()) {
        for (const [columnIndex, column] of sectionConfig.columns.entries()) {

          if (column.tenFrame) {
            const tenFrame = column.tenFrame.copy() as EmptyTenFrames;
            tenFrame.enableShadow(false);
            copiedHundredFrame.attachTenFrameToTenFrameGroup(
              tenFrame,
              copiedHundredFrame.tenFrameSection[sectionIndex].columns[columnIndex]
            );
          }
        }
      }
    }

    if (!!this.blueFrame) {
      const blueFrame = this.blueFrame.copy() as BlueHundredFrame;
      copiedHundredFrame.attach(blueFrame);
    }

    return copiedHundredFrame;
  }

  /**
   * Disable drag
   */
  public disableDrag(): void {
    this.draggable(false);
  }

  /**
   * Enable drag
   */
  public enableDrag(): void {
    this.draggable(true);
  }

  /**
   * Returns current shape
   */
  public getShape(): BaseShape {
    return this;
  }

  /**
   * Returns unique name of shape
   */
  public getUniqueName(): string {
    return this.uniqueName;
  }

  /**
   * Initiates events to handle snap to grid actions
   */
  public initiateSnapToGrid(): void {
    this.getShape().on('dragend', () => this.snapToGrid(this.baseUnit));
  }

  /**
   * Sets base unit
   * @param baseUnit
   */
  public setBaseUnit(baseUnit: number): void {
    this.baseUnit = baseUnit;
  }

  /**
   * Snaps object to grid
   * @param unit
   */
  public snapToGrid(unit: number): void {
    this.getShape().position(snapCalculation(this.getShape().position(), unit));
  }

  /**
   * Setup various rows and column
   * @private
   */
  private setupSectionConfig(): void {
    let offsetRow = (this.baseUnit * 10) / 3;
    for (let row = 1; row <= 2; row++) {

      const rowFrame: RowFrame = {
        columns: []
      };

      let offsetColumn = (this.baseUnit * 10) / 6;
      for (let column = 1; column <= 5; column++) {

        const tenFrameGroup = new HundredFrameTenGroup(this.tileDimension, this.baseUnit);
        rowFrame.columns.push({ group: tenFrameGroup, tenFrame: null });
        tenFrameGroup.getShape().y(offsetRow);
        tenFrameGroup.getShape().x(offsetColumn);

        offsetColumn = offsetColumn + YellowTenFrame.FrameDimension(this.tileDimension, this.baseUnit, 'height') + (this.baseUnit * 10) / 6;

        this.getShape().add(tenFrameGroup.getShape());
      }
      offsetRow = offsetRow + YellowTenFrame.FrameDimension(this.tileDimension, this.baseUnit, 'width') + (this.baseUnit * 10) / 3;

      this.tenFrameSection.push(rowFrame);
    }
  }

  /**
   * Add Ten frame
   * @param tenFrame
   */
  public addTenFrame(tenFrame: EmptyTenFrames): void {
    try {
      const sectionPosition = this.getSectionPosition(tenFrame);
      const section = this.tenFrameSection[sectionPosition.row].columns[sectionPosition.column];
      if (!section.tenFrame) {
        this.attachTenFrameToTenFrameGroup(tenFrame, section);
      } else if (tenFrame.canStack() && section.tenFrame.isAttachedToYellowFrame()) {
        if (tenFrame.isRotated()) {
          tenFrame.revertRotation();
        }
        section.tenFrame.stackItem(tenFrame);
      }

      if (this.isActive()) {
        tenFrame.setActive(false);
      }

    } catch (e) {
      if (e?.message === 'Row and column not found') {
        return;
      }
      throw e;
    }
  }

  /**
   * Returns position where the ten frame needs to be added
   * @param tenFrame
   * @private
   */
  private getSectionPosition(tenFrame: EmptyTenFrames): { row: number, column: number } {
    const copyTenFrame = tenFrame.copy();
    copyTenFrame.rotateBy90();
    copyTenFrame.scale({ x: 1, y: 1 });

    const tenFrameRect = tenFrame.intersection();

    let row = 0;
    for (const rowFrame of this.tenFrameSection) {
      let column = 0;
      for (const columnFrame of rowFrame.columns) {
        const columnRect = columnFrame.group.intersection();

        if (checkIntersection(columnRect, tenFrameRect, 0)) {
          return { row, column };
        }
        column++;
      }

      row++;
    }

    throw new SectionConfigNotFound('Row and column not found');
  }

  /**
   * Attaches ten frame to section
   * @param tenFrame
   * @param section
   * @protected
   */
  protected attachTenFrameToTenFrameGroup(tenFrame: EmptyTenFrames, section: ColumnFrame): void {
    tenFrame.attach(this);
    section.tenFrame = tenFrame;
    this.getShape().add(tenFrame);
    tenFrame.position(section.group.position());
  }


  /**
   * Reduce size according to reduction logic
   */
  public reduceSize(): void {
    this.scale({ x: this.reduceSizeBy(), y: this.reduceSizeBy() });
  }

  /**
   * Initiate events to tackle reduction in size
   */
  public initiateReduceSizeEvents(): void {
    this.getShape().on('dragstart', () => this.reduceSize());
    this.getShape().on('dragend', () => this.revertToOriginalSize());
  }

  /**
   * Reverts back to original size
   */
  public revertToOriginalSize(): void {
    this.scale({ x: 1, y: 1 });
  }

  /**
   * Reduction size multiplier
   * Range is from 0 to 1.
   */
  public reduceSizeBy(): number {
    return 0.5;
  }

  /**
   * Handles various tile events needed by this shape
   * @protected
   */
  protected tenFrameChangeEvents(): Subscription {
    const allowedEventKeys: InformationEmitterKeys[] = [InformationEmitterKeys.EmptyTenFrameRemoved];
    return informationEmitter.asObservable()
      .pipe(filter(result => allowedEventKeys.includes(result.key)))
      .subscribe(result => {
        const section = this.getEmptyTenFrameSection(result.shape as EmptyTenFrames);
        if (section) {
          section.tenFrame = null;
        }
      });
  }

  /**
   * Handles various blue frame events needed by this shape
   * @protected
   */
  protected blueFrameChangeEvents(): Subscription {
    const allowedEventKeys: InformationEmitterKeys[] = [InformationEmitterKeys.BlueFrameRemoved];
    return informationEmitter.asObservable()
      .pipe(filter(result => allowedEventKeys.includes(result.key)))
      .pipe(filter(result => !!this.blueFrame))
      .pipe(filter(result => this.blueFrame?.getUniqueName() === result.shape?.getUniqueName()))
      .pipe(tap(() => console.log('removing mapping of blue frame from hundred frame', this.blueFrame, this)))
      .subscribe(() => this.blueFrame = null);
  }

  /**
   * Returns the section the ten frame is mapped to otherwise returns false
   * @param tenFrame
   * @protected
   */
  protected getEmptyTenFrameSection(tenFrame: EmptyTenFrames): ColumnFrame | false {
    for (const rowFrame of this.tenFrameSection) {
      for (const columnFrame of rowFrame.columns) {

        if (columnFrame.tenFrame?.getUniqueName() === tenFrame.getUniqueName()) {
          return columnFrame;
        }
      }
    }
    return false;
  }

  destroy(): this {
    this.tenFrameChangeSubscription.unsubscribe();
    this.blueFrameChangeSubscription.unsubscribe();
    this.activeShapeSubscription.unsubscribe();
    return super.destroy();
  }

  /**
   * Adds tile to the ten frame mapped to the shape
   * @param tile
   */
  public addTile(tile: Tile): void {
    for (const rowFrame of this.tenFrameSection) {
      for (const columnFrame of rowFrame.columns) {
        if (columnFrame.tenFrame) {
          if (checkIntersection(columnFrame.tenFrame.intersection(), tile.intersection())) {
            columnFrame.tenFrame.addTile(tile);
          }
        }
      }
    }
  }

  /**
   * Adds yellow ten frame to an filled section ten frame if found and can be added
   * @param yellowFrame
   */
  public addYellowFrame(yellowFrame: YellowTenFrame): void {
    for (const rowFrame of this.tenFrameSection) {
      for (const columnFrame of rowFrame.columns) {
        const yellowFrameCopy = yellowFrame;
        yellowFrameCopy.rotateBy90();

        if (!checkIntersection(columnFrame.group.intersection(), yellowFrameCopy.intersection())) {
          yellowFrameCopy.revertRotation();
          continue;
        }

        if (!columnFrame.tenFrame) {
          yellowFrameCopy.revertRotation();
          return;
        }

        if (!!columnFrame.tenFrame.yellowTenFrame) {
          yellowFrameCopy.revertRotation();
          return;
        }

        if (columnFrame.tenFrame.canBeAttachedToYellowFrame()) {
          yellowFrameCopy.revertRotation();
          yellowFrame.addTenFrame(columnFrame.tenFrame);
        }
        return;
      }
    }
  }

  /**
   * Fills all empty sections with tile
   */
  public fillAllEmptySections(): void {
    for (const rowFrame of this.tenFrameSection) {
      for (const columnFrame of rowFrame.columns) {
        if (!columnFrame.tenFrame) {
          const tenFrame = new EmptyTenFrames(this.tileDimension, this.baseUnit);
          tenFrame.fillAllEmptySections();
          tenFrame.enableShadow(false);
          const yellowFrame = new YellowTenFrame(this.tileDimension, this.baseUnit);
          yellowFrame.addTenFrame(tenFrame);
          yellowFrame.enableShadow(false);
          this.attachTenFrameToTenFrameGroup(tenFrame, columnFrame);
        }
      }
    }
  }

  /**
   * Enable the ten frame drag
   * @param enable
   */
  public enableAllTenFrameDrag(enable = true): void {
    for (const rowFrame of this.tenFrameSection) {
      for (const columnFrame of rowFrame.columns) {
        if (columnFrame.tenFrame) {
          if (enable) {
            columnFrame.tenFrame.enableDrag();
            if (!columnFrame.tenFrame.yellowTenFrame) {
              columnFrame.tenFrame.enableAllTilesDrag();
            }
          } else {
            columnFrame.tenFrame.disableDrag();
            if (!columnFrame.tenFrame.yellowTenFrame) {
              columnFrame.tenFrame.enableAllTilesDrag(false);
            }
          }
        }
      }
    }
  }

  /**
   * Returns true if all the sections are filled with ten frame attached with yellow frame
   */
  public isComplete(): boolean {
    let baseCount = -1;

    for (const rowFrame of this.tenFrameSection) {
      for (const columnFrame of rowFrame.columns) {
        if (!columnFrame.tenFrame) {
          return false;
        }

        if (!columnFrame.tenFrame.isAttachedToYellowFrame()) {
          return false;
        }

        if (baseCount === -1) {
          baseCount = columnFrame.tenFrame.totalStackCount();
          continue;
        }

        if (baseCount !== columnFrame.tenFrame.totalStackCount()) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Returns true if can be attached to blue frame
   */
  public canBeAttachedToBlueFrame(): boolean {
    return this.isComplete() && !this.blueFrame;
  }

  public attach(shape: BlueHundredFrame): void {
    this.enableAllTenFrameDrag(false);
    this.add(shape);
    shape.moveToTop();
    this.allowDetachment(true);
    this.blueFrame = shape;
    this.setActive();
  }

  public detach(): void {
    this.enableAllTenFrameDrag(true);
  }

  public intersection(): IRect {
    return this.baseRect.getClientRect();
  }

  public initiateActiveShapeEvents(): void {
    const handler = (event: KonvaEventObject<MouseEvent | TouchEvent>, inDrag = false) => {
      if (!this.canBeActive()) {
        return;
      }

      const inMultiMode = (window as any).InMultiMode;

      const canActivateChild = !inMultiMode || (inMultiMode && !this.isActive());

      const shape = event.target;

      if (shape instanceof YellowTenFrame && canActivateChild) {
        shape.tenFrame?.setActive(true);
        this.setActive(false);
        return;
      }

      if (shape instanceof EmptyTenFrames && canActivateChild) {
        shape.setActive(true);
        this.setActive(false);
        return;
      }

      if (shape instanceof Tile && canActivateChild) {
        shape.setActive(true);
        this.setActive(false);
        return;
      }

      if (shape instanceof Rect || shape instanceof Circle) {
        if (shape.parent instanceof Tile || shape.parent instanceof EmptyTenFrames) {
          shape.parent.setActive(true);
          this.setActive(false);
          return;
        }

        if (shape.parent?.parent instanceof Tile || shape.parent?.parent instanceof EmptyTenFrames) {
          shape.parent.parent.setActive(true);
          this.setActive(false);
          return;
        }

        if (shape.parent?.parent instanceof YellowTenFrame) {
          shape.parent.parent.tenFrame?.setActive(true);
          this.setActive(false);
          return;
        }
      }

      if (shape instanceof Text && canActivateChild) {
        if (shape.parent instanceof Tile || shape.parent instanceof EmptyTenFrames) {
          shape.parent.setActive(true);
          this.setActive(false);
          this.getLayer()?.batchDraw();
          return;
        }
      }

      this.setActive(true, inDrag);
    };

    this.on('dragstart', (event) => handler(event, true));
    this.on('click tap', (event) => handler(event));
    this.activeShapeSubscription = informationEmitter.asObservable()
      .pipe(filter(event => event.key === InformationEmitterKeys.ActiveShapeChanged))
      .pipe(filter(event => event.uniqueName !== this.getUniqueName()))
      .pipe(filter(() => ((window as any).InMultiMode !== true)))
      .pipe(filter(() => this.isActive()))
      .subscribe(() => this.setActive(false));
  }

  /**
   * Returns true if shape is removable
   */
  public canBeRemoved(): boolean {
    return this.activeShapeState.removable;
  }

  /**
   * Returns true if shape is detachable
   */
  public canBeDetached(): boolean {
    return this.activeShapeState.detachable;
  }

  /**
   * Sets the removable state of shape
   * @param allowRemoval
   */
  public allowRemoval(allowRemoval = true): void {
    this.activeShapeState.removable = allowRemoval;
  }

  /**
   * Sets the detachable state of shape
   * @param allowDetachment
   */
  public allowDetachment(allowDetachment = true): void {
    this.activeShapeState.detachable = allowDetachment;
  }

  /**
   * Returns true if current shape is active
   */
  public isActive(): boolean {
    return this.activeShapeState.isActive;
  }

  /**
   * Setup active background shape for current shape
   */
  public setupActiveBackgroundShape(): void {
    this.activeBackground = new ActiveBackground(this.baseUnit);
    this.activeBackground.add(new Rect({
      height: this.baseRect.height(),
      width: this.baseRect.width(),
      strokeWidth: 0.5 * this.baseUnit,
      fill: 'rgba(128, 128, 128, 0.4)',
      stroke: 'rgba(128, 128, 128)',
    }));
    this.activeBackground.hide();
    this.add(this.activeBackground);
  }

  /**
   * Sets the shape as active/inactive
   * @param active
   * @param inDrag
   */
  public setActive(active = true, inDrag = false): void {
    const wasActive = this.isActive();

    if (this.isActive() && (window as any).InMultiMode && !inDrag) {
      active = false;
    }

    this.activeShapeState.isActive = active;
    this.isActive() ? this.getActiveBackground().show() : this.getActiveBackground().hide();
    this.activeBackground.moveToBottom();
    this.activeBackground.moveUp();
    if (this.isActive()) {
      if (!!this.blueFrame) {
        this.activeBackground.moveToTop();

      }
      informationEmitter.emit({
        key: InformationEmitterKeys.ActiveShapeChanged,
        shape: this,
        uniqueName: this.getUniqueName()
      });
    }

    if (wasActive && !this.isActive()) {
      informationEmitter.emit({
        key: InformationEmitterKeys.ActiveShapeRemoved,
        shape: this,
        uniqueName: this.getUniqueName()
      });
    }

    if ((window as any).InMultiMode && this.isActive()) {
      this.tenFrameSection.forEach(tenFrame => tenFrame.columns.forEach(column => {
        if (!!column.tenFrame) {
          column.tenFrame.setActive(false);
          column.tenFrame.setTileActiveStateToFalse();

          let stackedItem = column.tenFrame.stackedItem;

          while (stackedItem !== null) {
            (stackedItem as EmptyTenFrames).setActive(false);
            stackedItem = stackedItem.stackedItem;
          }
        }
      }));
    }
  }

  /**
   * Returns active background shape that maintains visual state of active shape
   */
  public getActiveBackground(): BaseShape {
    return this.activeBackground;
  }

  /**
   * Triggers detach action (detaches linked shape from another shape)
   */
  public triggerDetach(): void {
    if (this.blueFrame) {
      this.blueFrame.removeHundredFrame();
      this.blueFrame.destroy();
      this.setActive(true);
    }
    this.allowDetachment(false);
    this.getLayer()?.batchDraw();
  }

  /**
   * Triggers remove action (equal to trash)
   */
  public triggerRemove(): void {
    this.destroy();
  }

  /**
   * Allows the shape to be tackle active state handling
   * @param allow
   * @protected
   */
  public allowActivation(allow: boolean): void {
    this.activeShapeState.canBeActivated = allow;
    if (this.isActive() && !this.activeShapeState.canBeActivated) {
      this.setActive(false);
    }
  }

  /**
   * Returns true if the shape can be activated
   */
  public canBeActive(): boolean {
    return this.activeShapeState.canBeActivated;
  }

  /**
   * Enable activation of ten frame
   * @param enable
   */
  public enableAllTenFrameActive(enable = true): void {
    for (const row of this.tenFrameSection) {
      for (const column of row.columns) {
        if (!!column.tenFrame) {
          column.tenFrame.allowActivation(enable);
          column.tenFrame.enableYellowFrameActivation(enable);
        }
      }
    }
  }

  public enableShadow(enable = true): void {
    this.baseRect.shadowEnabled(enable);
  }
}

export class SectionConfigNotFound extends Error {
}
