import { Injectable } from '@angular/core';
import Konva from 'konva';
import Group = Konva.Group;
import { GridSlot } from '../../shapes/grid-slot/grid-slot';
import { ContainerService } from '../client/container.service';
import { DimensionScale } from '../../interfaces/dimension-scale';
import { environment } from '../../../environments/environment';
import { ShapeNames } from '../../interfaces/shape-names';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  /**
   * Grid
   * @protected
   */
  protected grid: Group;

  /**
   * Grid size
   * @protected
   */
  protected gridSize = environment.grid.gridSize;

  constructor(
    public containerService: ContainerService,
  ) {
    this.grid = new Group({
      x: 0,
      y: 0,
      name: ShapeNames.Grid
    });
  }

  /**
   * Returns new grid instance
   * @protected
   */
  public initialize(): void {
    for (let row = 0; row < 100; row++) {
      for (let column = 0; column < 100; column++) {
        const unit = this.containerService.getUnit();
        const dimension: DimensionScale = {height: unit * this.gridSize, width: unit * this.gridSize};
        const gridSlot = new GridSlot(dimension);
        gridSlot.getGroup().x(column * unit * this.gridSize);
        gridSlot.getGroup().y(row * unit * this.gridSize);
        this.grid.add(gridSlot.getGroup());
      }
    }
  }

  /**
   * Returns the grid
   */
  public getGrid(): Group {
    return this.grid;
  }
}
