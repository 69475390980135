import { Injectable } from '@angular/core';
import Konva from 'konva';
import { Tile } from '../../shapes/tile/tile';
import { EmptyTenFrames } from '../../shapes/empty-ten-frames/empty-ten-frames';
import { checkIntersection } from '../../utils/helpers';
import { ShapeNames } from '../../interfaces/shape-names';
import { YellowTenFrame } from '../../shapes/yellow-ten-frame/yellow-ten-frame';
import { EmptyHundredFrame } from '../../shapes/empty-hundred-frame/empty-hundred-frame';
import { informationEmitter, InformationEmitterKeys } from '../../utils/information-emitter';
import { filter } from 'rxjs/operators';
import { Menu } from '../../shapes/menu/menu';
import { BaseShape, Stackable } from '../../interfaces/shape-actions';
import { BlueHundredFrame } from '../../shapes/blue-hundred-frame/blue-hundred-frame';
import { TextContent } from '../../shapes/text-content/text-content';
import Layer = Konva.Layer;
import Group = Konva.Group;
import Shape = Konva.Shape;
import Vector2d = Konva.Vector2d;
import { SelectingShape } from '../../shapes/selecting-shape/selecting-shape';
import Stage = Konva.Stage;
import { timer } from 'rxjs';
import Rect = Konva.Rect;
import { ActiveBackground } from '../../shapes/active-background/active-background';

type ZoomInMax = 6;
type ZoomOutMax = -12;

const ZOOM_IN_MAX: ZoomInMax = 6;
const ZOOM_OUT_MAX: ZoomOutMax = -12;

interface ChildItemCount {
  menu: number;
  tile: number;
  emptyTenFrame: number;
  yellowTen: number;
  emptyHundredFrame: number;
  blueHundred: number;
  textContent: number;
}

@Injectable({
  providedIn: 'root'
})
export class LayerService {

  protected layer: Layer | undefined = undefined;

  protected zoomX: 0 | 1 | 2 | 3 | 5 | ZoomInMax | -1 | -2 | -3 | -5 | ZoomOutMax = 0;

  constructor() {
  }

  /**
   * Initialize the layer
   */
  public initialize(): void {
    this.layer = new Layer();
    this.initializeIntersectionObserver();
    informationEmitter.asObservable()
      .pipe(filter(result => result.key === InformationEmitterKeys.ReOrderShapes))
      .subscribe(() => this.reOrderChildren());
    this.initializeOrderHandler();
  }

  public initializeOrderHandler(): void {
    this.getLayer().on('dragstart', () => this.reOrderChildren());
  }

  /**
   * Returns the layer
   */
  public getLayer(): Layer {
    if (!this.layer) {
      throw new Error('Layer not initialized');
    }

    return this.layer;
  }

  /**
   * Add child to layer
   * @param children
   */
  public addItem(...children: Group[] | Shape[]): void {
    this.getLayer().add(...children);
  }

  public initializeMultiItemDrag(stage: Stage, activeShapesFun: () => (BaseShape & Stackable)[]): void {
    let originalPosition: Vector2d = { x: 0, y: 0 };
    let newPosition: Vector2d = { x: 0, y: 0 };
    let attemptDrag = false;
    let isBeingDragged = false;
    let shapeBeingDragged: false | BaseShape = false;

    stage.on('mousedown touchstart', (event) => {
      attemptDrag = false;
      isBeingDragged = false;
      shapeBeingDragged = false;
      if (!(window as any).InMultiMode) {
        return;
      }

      const targetShape = this.draggedShape(event.target);

      if (!targetShape) {
        return;
      }
      const activeShapes = activeShapesFun();
      (window as any).activeShapesFn = activeShapesFun;

      shapeBeingDragged = activeShapes.find(shape => shape?.getUniqueName() === targetShape?.getUniqueName()) || false;


      if (!shapeBeingDragged) {
        shapeBeingDragged = false;
        return;
      }

      if (!!(shapeBeingDragged as (BaseShape & Stackable)).parentStack) {
        shapeBeingDragged = false;
        return;
      }

      originalPosition = this.getRelativePosition(shapeBeingDragged);
      attemptDrag = true;
    });

    stage.on('mousemove touchmove', (event) => {
      if (attemptDrag && !!shapeBeingDragged) {
        isBeingDragged = true;
      } else {
        attemptDrag = false;
        shapeBeingDragged = false;
        isBeingDragged = false;
        return;
      }
    });

    stage.on('mouseup touchend', (event) => {

      if (!shapeBeingDragged || !attemptDrag || !isBeingDragged) {
        shapeBeingDragged = false;
        attemptDrag = false;
        isBeingDragged = false;
        return;
      }


      newPosition = shapeBeingDragged.position();
      newPosition = this.getRelativePosition(shapeBeingDragged);
      const diff: Vector2d = { x: newPosition.x - originalPosition.x, y: newPosition.y - originalPosition.y };

      // @todo drag items
      // question how to handle dragging of stacked tiles
      // question how to handle dragging of stacked ten frames
      // problem when tiles attached to ten-frame are moved they need to be detached
      // problem when ten frames attached to hundred frame are moved then ten frames need to be detached

      // getting list of shapes to check
      const shapes = activeShapesFun().reduce<BaseShape[]>((filteredShapes, shape) => {
        let toAddShape: BaseShape | null = shape;

        if (shape instanceof Tile) {
          if (!!shape.parentStack) {
            let parentShape = shape.parentStack;
            while (!!parentShape.parentStack) {
              parentShape = parentShape.parentStack;
            }

            toAddShape = parentShape;
          }
        }

        if (toAddShape === null) {
          return filteredShapes;
        }

        if (shape instanceof YellowTenFrame) {
          if (!!shape.tenFrame) {
            return filteredShapes;
          }
        }

        if (shape instanceof BlueHundredFrame) {
          if (!!shape.hundredFrame) {
            return filteredShapes;
          }
        }

        if (!filteredShapes.find(mappedShape => mappedShape.getUniqueName() === toAddShape?.getUniqueName())) {
          filteredShapes.push(toAddShape);
        }

        return filteredShapes;
      }, []);


      for (const shape of shapes) {
        const position = shape.getAbsolutePosition();

        if (shape instanceof Tile) {

          if (shape.attachedToTenFrame) {
            // @todo check when ten frame is attached to hundred frame
            const parentPosition = shape.parent?.position() as Vector2d;
            shape.detach();
            const absolutePosition = shape.position();
            shape.position({
              x: absolutePosition.x + diff.x + parentPosition.x,
              y: absolutePosition.y + diff.y + parentPosition.y
            });
            continue;
          }

        }

        if (shape instanceof EmptyTenFrames) {
          if (shape.emptyHundredFrame) {
            const parentPosition = shape.parent?.position() as Vector2d;
            shape.detach(shape.emptyHundredFrame);
            const absolutePosition = shape.position();
            shape.position({
              x: absolutePosition.x + diff.x + parentPosition.x,
              y: absolutePosition.y + diff.y + parentPosition.y
            });
            continue;
          }
        }

        if (shape.getUniqueName() === shapeBeingDragged.getUniqueName()) {
          continue;
        }

        shape.position({
          x: position.x + diff.x,
          y: position.y + diff.y,
        });
      }
      stage.batchDraw();

      attemptDrag = false;
      isBeingDragged = false;
      shapeBeingDragged = false;
    });
  }

  private draggedShape(target: any): false | BaseShape & Stackable {
    if (this.isKnownInstance(target)) {
      return target;
    }

    if (this.isKnownInstance(target.parent)) {
      return target.parent;
    }

    if (this.isKnownInstance(target.parent?.parent)) {
      return target.parent.parent;
    }

    if (this.isKnownInstance(target.parent?.parent?.parent)) {
      return target.parent?.parent?.parent;
    }

    return false;
  }

  private isKnownInstance(target: any): boolean {
    return (target instanceof EmptyTenFrames) ||
      (target instanceof Tile) ||
      (target instanceof EmptyHundredFrame) ||
      (target instanceof YellowTenFrame) ||
      (target instanceof BlueHundredFrame);
  }

  public initializeIntersectionObserver(): void {
    this.getLayer().on('dragend', (e) => {
      const target = e.target;

      if (target instanceof SelectingShape) {
        return;
      }

      for (const child of this.getLayer().children) {
        if (child === target) {
          return;
        }

        if (child instanceof SelectingShape) {
          return;
        }

        if ((target as any) instanceof Tile) {
          this.checkTileIntersections((target as any), child as any);
        }

        if ((target as any) instanceof EmptyTenFrames) {
          this.checkEmptyTenFrameIntersections((target as any), child as any);
        }

        if ((target as any) instanceof YellowTenFrame) {
          this.checkYellowTileIntersections(target as any, child as any);
        }

        if ((target as any) instanceof BlueHundredFrame) {
          this.checkBlueTileIntersections(target as any, child as any);
        }
      }
    });
  }

  /**
   * Checks tile intersections and handles the attach conditions
   * @param tile
   * @param child
   * @protected
   */
  protected checkTileIntersections<T extends BaseShape>(tile: Tile, child: T): void {

    if (checkIntersection(child.intersection(), tile.intersection())) {
      if (child instanceof EmptyTenFrames) {
        child.addTile(tile);
        return;
      }

      if (child.name() === ShapeNames.LeftMenu) {
        tile.destroy();
        return;
      }

      if (!(tile.parent instanceof Layer)) {
        tile.detach();
        tile.remove();
        return;
      }

      if (child instanceof EmptyHundredFrame) {
        child.addTile(tile);
        return;
      }
    }
  }

  /**
   * Checks yellow tile intersection and handles attach condition
   * @param yellowFrame
   * @param child
   * @protected
   */
  protected checkYellowTileIntersections<T extends BaseShape>(yellowFrame: YellowTenFrame, child: T): void {
    if (checkIntersection(child.intersection(), yellowFrame.intersection())) {
      // when intersecting the empty ten frame
      if (child instanceof EmptyTenFrames) {
        yellowFrame.addTenFrame(child);
        return;
      }

      if (child.name() === ShapeNames.LeftMenu) {
        yellowFrame.destroy();
        return;
      }

      if (child instanceof EmptyHundredFrame) {
        child.addYellowFrame(yellowFrame);
        return;
      }
    }
  }

  /**
   * Checks blue tile intersection and handles attach condition
   * @param blueFrame
   * @param child
   * @protected
   */
  protected checkBlueTileIntersections<T extends BaseShape>(blueFrame: BlueHundredFrame, child: T): void {

    if (checkIntersection(child.intersection(), blueFrame.intersection())) {
      // when intersecting the empty ten frame
      if (child instanceof EmptyHundredFrame) {
        blueFrame.addHundredFrame(child);
        return;
      }

      if (child.name() === ShapeNames.LeftMenu) {
        blueFrame.destroy();
        return;
      }
    }
  }

  /**
   * Check empty ten frame intersections and handles attach condition
   * @param emptyTenFrame
   * @param child
   * @protected
   */
  protected checkEmptyTenFrameIntersections<T extends BaseShape>(emptyTenFrame: EmptyTenFrames, child: T): void {
    if (!child.intersection) {
      return;
    }

    if (checkIntersection(child.intersection(), emptyTenFrame.intersection())) {
      // when intersecting the empty ten frame
      if (child.name() === ShapeNames.LeftMenu) {
        emptyTenFrame.destroy();
        return;
      }

      if (child instanceof EmptyHundredFrame) {
        child.addTenFrame(emptyTenFrame);
        return;
      }

      if (child instanceof EmptyTenFrames) {
        if (
          (child as EmptyTenFrames).canStack()
          && (child as EmptyTenFrames).isAttachedToYellowFrame()
          && emptyTenFrame.isAttachedToYellowFrame()
        ) {
          (child as EmptyTenFrames).stackItem(emptyTenFrame);
          return;
        }
      }
    }
  }

  /**
   * Sets the order of children
   * @protected
   */
  protected reOrderChildren(): void {
    const childLayerCount = this.childLayerCount();
    const children: any[] = [];
    this.getLayer().children.each((child) => children.push(child));
    children.sort((a, b) => a.countManager >= b.countManager ? 1 : -1).forEach((child) => {
      this.setChildLayer(child as any, childLayerCount);
    });
    this.getLayer().batchDraw();
  }

  /**
   * Sets the child layer based on total shapes available
   * @param child
   * @param childLayerCount
   * @private
   */
  private setChildLayer(child: BaseShape, childLayerCount: ChildItemCount): void {
    let count = 0;
    if (child instanceof Tile) {
      count = count + childLayerCount.emptyHundredFrame + childLayerCount.emptyTenFrame + childLayerCount.textContent;
    }

    if (child instanceof EmptyTenFrames) {
      count = count + childLayerCount.emptyHundredFrame + childLayerCount.textContent;
    }

    if (child instanceof YellowTenFrame) {
      count = count + childLayerCount.emptyHundredFrame
        + childLayerCount.emptyTenFrame
        + childLayerCount.tile
        + childLayerCount.textContent;
    }

    if (child instanceof BlueHundredFrame) {
      count = count + childLayerCount.emptyHundredFrame
        + childLayerCount.emptyTenFrame
        + childLayerCount.tile
        + childLayerCount.yellowTen
        + childLayerCount.textContent;
    }

    child.moveToBottom();
    for (let startFrom = 1; startFrom <= count; startFrom++) {
      child.moveUp();
    }
  }

  /**
   * Returns total types of shapes available in layer based on types to consider
   * @private
   */
  private childLayerCount(): ChildItemCount {
    const childItemCount: ChildItemCount = {
      menu: 0,
      tile: 0,
      yellowTen: 0,
      emptyHundredFrame: 0,
      emptyTenFrame: 0,
      blueHundred: 0,
      textContent: 0
    };

    this.getLayer().children.each((child) => {
      if (child instanceof Tile) {
        childItemCount.tile++;
        return;
      }

      if (child instanceof YellowTenFrame) {
        childItemCount.yellowTen++;
        return;
      }

      if (child instanceof EmptyTenFrames) {
        childItemCount.emptyTenFrame++;
        return;
      }

      if (child instanceof EmptyHundredFrame) {
        childItemCount.emptyHundredFrame++;
        return;
      }

      if (child instanceof BlueHundredFrame) {
        childItemCount.blueHundred++;
        return;
      }
      if (child instanceof TextContent) {
        childItemCount.textContent++;
        return;
      }
    });
    return childItemCount;
  }

  /**
   * Zoom out in workspace
   */
  public zoomOut(): void {
    if (this.zoomX === ZOOM_OUT_MAX) {
      return;
    }
    const originalScale = this.getLayer().scale();
    const newScale = { x: originalScale.x * 0.95, y: originalScale.y * 0.95 };
    this.getLayer().scale(newScale);
    this.getLayer().position(this.newZoomPosition(originalScale, newScale));
    this.getLayer().batchDraw();
    this.zoomX--;

    if (this.zoomX === 0) {
      this.resetZoom();
    }
    informationEmitter.emit({
      shape: null,
      uniqueName: '',
      key: InformationEmitterKeys.ZoomOut
    });
  }

  /**
   * Zoom in workspace
   */
  public zoomIn(): void {
    if (this.zoomX === ZOOM_IN_MAX) {
      return;
    }
    const originalScale = this.getLayer().scale();
    const newScale = { x: originalScale.x * 1.05, y: originalScale.y * 1.05 };

    this.getLayer().scale(newScale);

    this.getLayer().position(this.newZoomPosition(originalScale, newScale));

    this.getLayer().batchDraw();
    this.zoomX++;

    if (this.zoomX === 0) {
      this.resetZoom();
    }

    informationEmitter.emit({
      shape: null,
      uniqueName: '',
      key: InformationEmitterKeys.ZoomIn
    });
  }

  protected newZoomPosition(oldScale: Vector2d, newScale: Vector2d): Vector2d {
    const center = {
      x: this.getLayer().width() / 2,
      y: this.getLayer().height() / 2,
    };

    const relatedTo = {
      x: (center.x - this.getLayer().x()) / oldScale.x,
      y: (center.y - this.getLayer().y()) / oldScale.y,
    };

    return {
      x: center.x - relatedTo.x * newScale.x,
      y: center.y - relatedTo.y * newScale.y,
    };
  }

  /**
   * Reset zoom to normal level
   */
  public resetZoom(): void {
    const originalScale = this.getLayer().scale();
    this.getLayer().scale({ x: 1, y: 1 });
    this.getLayer().position(this.newZoomPosition(originalScale, { x: 1, y: 1 }));
    this.getLayer().batchDraw();
    this.zoomX = 0;
  }

  /**
   * Remove all children
   */
  public clearAll(): void {
    this.layer?.removeChildren();
    this.layer?.batchDraw();
    informationEmitter.emit({
      key: InformationEmitterKeys.ActiveShapeChanged,
      shape: null,
      uniqueName: 'should-not-match'
    });
  }

  private getRelativePosition(shape: BaseShape): Vector2d {
    if (shape.parent instanceof Layer) {
      return shape.position();
    }

    if (shape instanceof Tile) {
      if (shape.attachedToTenFrame) {
        const parentPosition = shape.parent?.position() as Vector2d;
        const shapePosition = shape.position();
        return {
          x: shapePosition.x + parentPosition.x,
          y: shapePosition.y + parentPosition.y,
        };
      }
    }

    if (shape instanceof EmptyTenFrames) {
      if (shape.emptyHundredFrame) {
        const parentPosition = shape.parent?.position() as Vector2d;
        const shapePosition = shape.position();
        return {
          x: shapePosition.x + parentPosition.x,
          y: shapePosition.y + parentPosition.y,
        };
      }
    }
    return { x: 0, y: 0 };
  }
}
