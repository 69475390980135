import Konva from 'konva';
import {
  ActiveShape,
  ActiveShapeState,
  BaseShape,
  ReduceSizeOnDrag,
  ShapeIsDraggable,
  SnapToGrid
} from '../../interfaces/shape-actions';
import { EmptyHundredFrame } from '../empty-hundred-frame/empty-hundred-frame';
import { DimensionScale } from '../../interfaces/dimension-scale';
import { generateUniqueName, snapCalculation } from '../../utils/helpers';
import { informationEmitter, InformationEmitterKeys } from '../../utils/information-emitter';
import Group = Konva.Group;
import Rect = Konva.Rect;
import Circle = Konva.Circle;
import ContainerConfig = Konva.ContainerConfig;
import { IRect } from 'konva/types/types';
import { filter } from 'rxjs/operators';
import { ActiveBackground } from '../active-background/active-background';
import { Subscription, timer } from 'rxjs';

export class BlueHundredFrame extends Group implements BaseShape, ShapeIsDraggable, SnapToGrid, ReduceSizeOnDrag, ActiveShape {

  /**
   * Active shape state
   */
  public activeShapeState: ActiveShapeState = {
    detachable: false,
    removable: true,
    isActive: false,
    canBeActivated: true,
  };

  // @ts-ignore
  private activeShapeSubscription: Subscription;

  // @ts-ignore
  protected activeBackground: ActiveBackground;

  /**
   * Returns the unique name
   * @protected
   */
  protected uniqueName: string;

  /**
   * Hundred frame attached to this blue tile
   * @protected
   */
  public hundredFrame: EmptyHundredFrame | null = null;

  protected baseRect: Rect;

  public countManager: number;

  public static FrameDimension(tileDimension: DimensionScale, baseUnit: number, forType: 'width' | 'height'): number {
    return EmptyHundredFrame.FrameDimension(tileDimension, baseUnit, forType);
  }

  constructor(protected tileDimension: DimensionScale, public baseUnit: number, protected containerConfig?: ContainerConfig) {
    super(containerConfig);
    this.countManager = (window as any).CounterManager;
    (window as any).CounterManager++;
    this.countManager = (window as any).CounterManager;
    (window as any).CounterManager++;
    this.uniqueName = generateUniqueName();
    this.initiateSnapToGrid();
    this.baseRect = new Rect({
      x: 0,
      y: 0,
      fill: '#89B1EF',
      height: 0.99 * BlueHundredFrame.FrameDimension(tileDimension, baseUnit, 'width'),
      width: 0.99 * BlueHundredFrame.FrameDimension(tileDimension, baseUnit, 'height'),
      shadowEnabled: true,
      shadowColor: '#789DD6',
      shadowOffset: { x: 10, y: 10 },
      shadowOpacity: 0.4,
      shadowBlur: 5
    });
    this.add(this.baseRect);
    this.add(new Circle({
      radius: tileDimension.width * 0.8,
      fill: 'black',
      opacity: 0.2,
      x: BlueHundredFrame.FrameDimension(tileDimension, baseUnit, 'height') / 2,
      y: BlueHundredFrame.FrameDimension(tileDimension, baseUnit, 'width') / 2,
    }));
    this.on('dragstart', () => informationEmitter.emit({
      key: InformationEmitterKeys.ReOrderShapes,
      shape: this,
      uniqueName: this.getUniqueName()
    }));
    this.initiateReduceSizeEvents();
    this.initiateActiveShapeEvents();
    this.setupActiveBackgroundShape();
    this.dragBoundFunc((pos) => {
      if (pos.x <= 10) {
        pos.x = 10;
      }

      if (pos.y <= 10) {
        pos.y = 10;
      }

      const stage = this.getLayer()?.getStage();
      if (!!stage) {
        const maxXValue = (stage.width() - (BlueHundredFrame.FrameDimension(this.tileDimension, this.baseUnit, 'width') / 2));
        const maxYValue = (stage.height() - (BlueHundredFrame.FrameDimension(this.tileDimension, this.baseUnit, 'height') / 2)) - 50;
        if (pos.x >= maxXValue) {
          pos.x = maxXValue;
        }

        if (pos.y >= maxYValue) {
          pos.y = maxYValue;
        }
      }
      return pos;
    });
  }

  /**
   * Returns unique name of object
   */
  public getUniqueName(): string {
    return this.uniqueName;
  }

  /**
   * Creates a copy of current object
   */
  public copy(): BaseShape {
    return new BlueHundredFrame(this.tileDimension, this.baseUnit, this.containerConfig);
  }

  /**
   * Disables drag
   */
  public disableDrag(): void {
    this.draggable(false);
  }

  /**
   * Enables drag
   */
  public enableDrag(): void {
    this.draggable(true);
  }

  /**
   * Returns the main group
   */
  public getShape(): BaseShape {
    return this;
  }

  /**
   * Initiates the events to to handle snap to grid actions
   */
  public initiateSnapToGrid(): void {
    this.getShape().on('dragend', () => this.snapToGrid(this.baseUnit));
  }

  /**
   * Sets new base unit
   * @param baseUnit
   */
  public setBaseUnit(baseUnit: number): void {
    this.baseUnit = baseUnit;
  }

  /**
   * Snaps the shape to grid
   * @param unit
   */
  public snapToGrid(unit: number): void {
    this.getShape().position(snapCalculation(this.getShape().position(), unit));
  }

  /**
   * Adds an hundred frame to the shape
   * @param hundredFrame
   */
  public addHundredFrame(hundredFrame: EmptyHundredFrame): void {
    if (!hundredFrame.canBeAttachedToBlueFrame() || !!this.hundredFrame) {
      return;
    }
    this.hundredFrame = hundredFrame;
    hundredFrame.attach(this);
    this.allowDetachment(true);
    this.position({ x: 2, y: 2 });
    this.disableDrag();
  }

  /**
   * Removes ten frame mapped to this layer
   */
  public removeHundredFrame(): void {
    if (!this.hundredFrame) {
      return;
    }
    this.moveTo(this.getLayer());
    this.hundredFrame.detach();
    this.hundredFrame = null;
  }

  /**
   * Reduce size according to reduction logic
   */
  public reduceSize(): void {
    this.scale({ x: this.reduceSizeBy(), y: this.reduceSizeBy() });
  }

  /**
   * Initiate events to tackle reduction in size
   */
  public initiateReduceSizeEvents(): void {
    this.getShape().on('dragstart', () => this.reduceSize());
    this.getShape().on('dragend', () => this.revertToOriginalSize());
  }

  /**
   * Reverts back to original size
   */
  public revertToOriginalSize(): void {
    this.scale({ x: 1, y: 1 });
  }

  /**
   * Reduction size multiplier
   * Range is from 0 to 1.
   */
  public reduceSizeBy(): number {
    return 0.5;
  }

  public destroy(): this {
    informationEmitter.emit({
      key: InformationEmitterKeys.BlueFrameRemoved,
      shape: this,
      uniqueName: this.getUniqueName()
    });
    this.activeShapeSubscription.unsubscribe();
    return super.destroy();
  }

  /**
   * Returns intersection shape dimension to check
   */
  public intersection(): IRect {
    return this.baseRect.getClientRect();
  }

  public initiateActiveShapeEvents(): void {
    const handler = (inDrag = false) => {
      if (!this.hundredFrame) {
        if (this.canBeActive()) {
          this.setActive(true, inDrag);
        }
      }
    };
    this.on('dragstart', () => handler(true));
    this.on('touchstart', () => handler());
    this.on('click', () => handler());
    this.activeShapeSubscription = informationEmitter.asObservable()
      .pipe(filter(event => event.key === InformationEmitterKeys.ActiveShapeChanged))
      .pipe(filter(event => event.uniqueName !== this.getUniqueName()))
      .pipe(filter(() => ((window as any).InMultiMode !== true)))
      .pipe(filter(() => this.isActive()))
      .subscribe(() => this.setActive(false));
  }

  /**
   * Returns true if shape is removable
   */
  public canBeRemoved(): boolean {
    return this.activeShapeState.removable;
  }

  /**
   * Returns true if shape is detachable
   */
  public canBeDetached(): boolean {
    return this.activeShapeState.detachable;
  }

  /**
   * Sets the removable state of shape
   * @param allowRemoval
   */
  public allowRemoval(allowRemoval = true): void {
    this.activeShapeState.removable = allowRemoval;
  }

  /**
   * Sets the detachable state of shape
   * @param allowDetachment
   */
  public allowDetachment(allowDetachment = true): void {
    this.activeShapeState.detachable = allowDetachment;
  }

  /**
   * Returns true if current shape is active
   */
  public isActive(): boolean {
    return this.activeShapeState.isActive;
  }

  /**
   * Setup active background shape for current shape
   */
  public setupActiveBackgroundShape(): void {
    this.activeBackground = new ActiveBackground(this.baseUnit);
    this.activeBackground.add(new Rect({
      height: this.baseRect.height(),
      width: this.baseRect.width(),
      strokeWidth: 0.5 * this.baseUnit,
      fill: 'rgba(128, 128, 128, 0.4)',
      stroke: 'rgba(128, 128, 128)',
    }));
    this.activeBackground.hide();
    this.add(this.activeBackground);
  }

  /**
   * Sets the shape as active/inactive
   * @param active
   * @param inDrag
   */
  public setActive(active = true, inDrag = false): void {
    const wasActive = this.isActive();

    if (this.isActive() && (window as any).InMultiMode && !inDrag) {
      active = false;
    }

    this.activeShapeState.isActive = active;
    this.isActive() ? this.getActiveBackground().show() : this.getActiveBackground().hide();
    if (this.isActive()) {
      informationEmitter.emit({
        key: InformationEmitterKeys.ActiveShapeChanged,
        shape: this,
        uniqueName: this.getUniqueName()
      });
    }

    if (wasActive && !this.isActive()) {
      informationEmitter.emit({
        key: InformationEmitterKeys.ActiveShapeRemoved,
        shape: this,
        uniqueName: this.getUniqueName()
      });
    }
  }

  /**
   * Returns active background shape that maintains visual state of active shape
   */
  public getActiveBackground(): BaseShape {
    return this.activeBackground;
  }

  /**
   * Triggers detach action (detaches linked shape from another shape)
   */
  public triggerDetach(): void {
    if (!!this.hundredFrame) {
      this.removeHundredFrame();
    }
    this.destroy();
  }

  /**
   * Triggers remove action (equal to trash)
   */
  public triggerRemove(): void {
    if (!!this.hundredFrame) {
      this.hundredFrame.destroy();
      return;
    }

    this.destroy();
  }

  /**
   * Allows the shape to be tackle active state handling
   * @param allow
   * @protected
   */
  public allowActivation(allow: boolean): void {
    this.activeShapeState.canBeActivated = allow;
    if (this.isActive() && !this.activeShapeState.canBeActivated) {
      this.setActive(false);
    }
  }

  /**
   * Returns true if the shape can be activated
   */
  public canBeActive(): boolean {
    return this.activeShapeState.canBeActivated;
  }

  public enableShadow(enable = true): void {
    this.baseRect.shadowEnabled(enable);
  }
}
