import { Injectable } from '@angular/core';
import Konva from 'konva';
import { ShapesService } from '../tile/shapes.service';
import { ContainerService } from '../client/container.service';
import { LayerService } from '../layer/layer.service';
import { ActiveShape, BaseShape } from '../../interfaces/shape-actions';
import { EmptyTenFrames } from '../../shapes/empty-ten-frames/empty-ten-frames';
import { YellowTenFrame } from '../../shapes/yellow-ten-frame/yellow-ten-frame';
import { EmptyHundredFrame } from '../../shapes/empty-hundred-frame/empty-hundred-frame';
import { informationEmitter, InformationEmitterKeys } from '../../utils/information-emitter';
import { Menu } from '../../shapes/menu/menu';
import { StageService } from '../stage/stage.service';
import { timer } from 'rxjs';
import { TextContent } from '../../shapes/text-content/text-content';
import { FreeHandDrawService } from '../free-hand-draw/free-hand-draw.service';
import { filter } from 'rxjs/operators';
import Group = Konva.Group;
import Rect = Konva.Rect;
import Text = Konva.Text;
import Line = Konva.Line;
import Layer = Konva.Layer;
import { Tile } from '../../shapes/tile/tile';

@Injectable({
  providedIn: 'root'
})
export class LeftMenuService {

  /**
   * The main container
   * @protected
   */
  protected leftMenuGroup: Menu;

  protected menuRect: Rect;

  protected menuLayer: Layer;

  protected counter = 1;

  constructor(
    public shapesService: ShapesService,
    public containerService: ContainerService,
    public layerService: LayerService,
    public stageService: StageService,
    public freeHandService: FreeHandDrawService,
  ) {
    this.menuLayer = new Layer();
    this.leftMenuGroup = new Menu(this.containerService.getUnit());
    this.menuRect = new Rect({
      x: 0,
      y: 0,
      height: this.containerService.getHeight(),
      width: 55 * this.containerService.getUnit(),
      fillLinearGradientStartPoint: { x: 0, y: 0 },
      fillLinearGradientEndPoint: { x: 55 * this.containerService.getUnit(), y: this.containerService.getHeight() },
      fillLinearGradientColorStops: [0, '#f0f3f8', 0.32, '#629fc9', 1, '#336699'],
      shadowOffset: { x: 2 * this.containerService.getUnit(), y: 5 * this.containerService.getUnit('height') },
      shadowEnabled: false,
      shadowOpacity: 0.3,
      shadowColor: 'black'
    });
  }

  /**
   * Initialize the menu
   */
  public initialize(): void {
    this.resetHeight();
    this.getMenu().add(this.menuRect);
    this.menuLayer.add(this.getMenu());
    this.stageService.addLayer(this.menuLayer);
    this.createLogo();
    this.createFilledHundredFrame();
    this.createEmptyHundredFrame();
    this.createFilledTenFrame();
    this.createYellowTenFrame();
    this.createEmptyTenFrame();
    this.createBlueTenFrame();
    this.createCounter();
    this.createTile();
    this.getMenu().moveToBottom();
    informationEmitter.emit({
      key: InformationEmitterKeys.ActiveShapeChanged,
      shape: null as any,
      uniqueName: 'will-no-match'
    });
  }

  public getMenu(): Group {
    return this.leftMenuGroup;
  }

  public getMenuLayer(): Layer {
    return this.menuLayer;
  }

  public createLogo(): void {
    Konva.Image.fromURL('/assets/logo.png', (imageNode: Konva.Image) => {
      imageNode.setAttrs({
        x: 15 * this.containerService.getUnit(),
        y: 10 * this.containerService.getUnit(),
        width: this.containerService.getUnit() * 25,
        height: this.containerService.getUnit() * 20,
      });
      this.menuLayer.add(imageNode);
      this.menuLayer.batchDraw();
    });

  }

  /**
   * Creates tiles for using
   * @protected
   */
  protected createTile(): void {
    const visualTile = this.shapesService.createTile();
    visualTile.setPosition({ x: 25 * this.containerService.getUnit(), y: 45 * this.containerService.getUnit() });
    visualTile.snapToGrid(this.containerService.getUnit());
    this.getMenu().add(visualTile);
    visualTile.allowActivation(false);
    this.createDraggableShape(visualTile, this.dragStartOfTile.bind(this) as any);

    const line = new Line({
      points: [
        2 * this.containerService.getUnit(),
        60 * this.containerService.getUnit(),
        52 * this.containerService.getUnit(),
        60 * this.containerService.getUnit(),
      ],
      stroke: 'rgba(255,255,255,0.3)',
      strokeWidth: 2,
    });
    this.getMenuLayer().add(line);
  }

  /**
   * Creates a draggable shape of the reference shape and recreates a duplicate on drag start of copied one
   * @param referenceShape
   * @param extraActions
   * @protected
   */
  protected createDraggableShape<T extends BaseShape & ActiveShape>(
    referenceShape: T,
    extraActions: ((shape: BaseShape & ActiveShape) => void) | null = null
  ): void {
    const copiableShape = referenceShape.copy() as BaseShape & ActiveShape;
    copiableShape.setBaseUnit(this.containerService.getUnit());
    copiableShape.setPosition(referenceShape.getPosition());
    copiableShape.draggable(true);
    this.menuLayer.add(copiableShape);
    copiableShape.moveToTop();
    if (!!extraActions) {
      extraActions(copiableShape);
    }

    copiableShape.allowActivation(false);
    let dragStartCompleted = false;

    const subscriptionDisableDrag = informationEmitter.asObservable()
      .pipe(filter(event => event.key === InformationEmitterKeys.DrawModeActivated))
      .subscribe(() => copiableShape.draggable(false));
    const subscriptionEnableDrag = informationEmitter.asObservable()
      .pipe(filter(event => event.key === InformationEmitterKeys.DrawModeDeActivated))
      .subscribe(() => copiableShape.draggable(true));

    copiableShape.on('dragstart', () => {
      if (!dragStartCompleted) {
        copiableShape.enableShadow(false);
        copiableShape.allowActivation(true);
        copiableShape.setActive(true);
        this.createDraggableShape(referenceShape, extraActions);
        copiableShape.moveTo(this.layerService.getLayer());
        if (this.freeHandService.isInDrawState()) {
          copiableShape.draggable(false);
        }
        informationEmitter.emit({
          key: InformationEmitterKeys.ReOrderShapes,
          shape: copiableShape,
          uniqueName: copiableShape.getUniqueName()
        });
        // to reorder the elements in main layer
        // its a hack @todo figure it out later
        timer(10)
          .subscribe(() => informationEmitter.emit({
            key: InformationEmitterKeys.ReOrderShapes,
            shape: copiableShape,
            uniqueName: copiableShape.getUniqueName()
          }));
        this.layerService.getLayer().batchDraw();
        this.menuLayer.batchDraw();
        subscriptionDisableDrag.unsubscribe();
        subscriptionEnableDrag.unsubscribe();
        dragStartCompleted = true;
      }
    });
    let dragEndComplete = false;
    copiableShape.on('dragend', () => {
      if (!dragEndComplete) {
        dragEndComplete = true;
        informationEmitter.emit({
          key: InformationEmitterKeys.ReOrderShapes,
          shape: copiableShape,
          uniqueName: copiableShape.getUniqueName()
        });
      }
    });
  }

  /**
   * Creates an empty ten frame set menu
   * @protected
   */
  protected createEmptyTenFrame(): void {
    const visualTenFrame = this.shapesService.createEmptyTenFrames();
    visualTenFrame.setPosition({
      x: 10 * this.containerService.getUnit(),
      y: 66 * this.containerService.getUnit()
    });
    visualTenFrame.snapToGrid(this.containerService.getUnit());
    visualTenFrame.scale({ x: 0.5, y: 0.5 });

    this.createDraggableShape(visualTenFrame, this.adjustTenFrameRotationOnDrag);

    this.getMenu().add(visualTenFrame);

    const line = new Line({
      points: [
        2 * this.containerService.getUnit(),
        80 * this.containerService.getUnit(),
        52 * this.containerService.getUnit(),
        80 * this.containerService.getUnit(),
      ],
      stroke: 'rgba(255,255,255,0.3)',
      strokeWidth: 2,
    });
    this.getMenuLayer().add(line);
  }

  /**
   * Creates an empty ten frame set menu
   * @protected
   */
  protected createFilledTenFrame(): void {
    const visualTenFrame = this.shapesService.createEmptyTenFrames();
    visualTenFrame.setPosition({
      x: 30 * this.containerService.getUnit(),
      y: 66 * this.containerService.getUnit()
    });
    visualTenFrame.fillAllEmptySections();
    visualTenFrame.enableRemovalOfTiles(false);
    visualTenFrame.snapToGrid(this.containerService.getUnit());
    visualTenFrame.scale({ x: 0.5, y: 0.5 });
    visualTenFrame.enableAllTilesDrag(false);

    this.createDraggableShape(visualTenFrame, this.setupFilledTenFrameAfterCreateHandler.bind(this) as any);

    this.getMenu().add(visualTenFrame);
  }

  /**
   * Adjusts rotation of ten frame copied shape
   * @param shape
   * @private
   */
  private adjustTenFrameRotationOnDrag(shape: BaseShape): void {
    let hasDragCompleted = false;
    shape.scale({ x: 0.5, y: 0.5 });

    shape.on('dragstart', () => {
      if (!hasDragCompleted) {
        // shape.rotate(-90);
        hasDragCompleted = true;
      }
    });
  }

  /**
   * After creation handler for copied ten frame to setup extra actions
   * @param shape
   * @protected
   */
  protected setupFilledTenFrameAfterCreateHandler(shape: EmptyTenFrames): void {
    shape.enableRemovalOfTiles(false);
    shape.enableAllTilesActivation(false);
    this.adjustTenFrameRotationOnDrag(shape);
    let enabledActions = false;
    shape.on('dragend', () => {
      if (!enabledActions) {
        shape.enableAllTilesDrag();
        shape.enableRemovalOfTiles();
        shape.enableAllTilesActivation();
        enabledActions = true;
      }
    });
  }

  /**
   * Creates a yellow ten frame
   * @protected
   */
  protected createYellowTenFrame(): void {
    const visualTenFrame = this.shapesService.createYellowTenFrame();
    visualTenFrame.setPosition({
      x: 18.5 * this.containerService.getUnit(),
      y: 90 * this.containerService.getUnit()
    });
    visualTenFrame.snapToGrid(this.containerService.getUnit());
    visualTenFrame.scale({ x: 0.5, y: 0.5 });

    this.createDraggableShape(visualTenFrame, this.setupYellowTenFrameAfterCreateHandler as any);
    this.getMenu().add(visualTenFrame);

    const line = new Line({
      points: [
        2 * this.containerService.getUnit(),
        105 * this.containerService.getUnit(),
        52 * this.containerService.getUnit(),
        105 * this.containerService.getUnit(),
      ],
      stroke: 'rgba(255,255,255,0.3)',
      strokeWidth: 2,
    });
    this.getMenuLayer().add(line);
  }

  /**
   * Rotates the yellow frame and moves the copied item to top
   * @param shape
   * @protected
   */
  protected setupYellowTenFrameAfterCreateHandler(shape: YellowTenFrame): void {
    let hasDragCompleted = false;
    shape.scale({ x: 0.5, y: 0.5 });

    shape.on('dragstart', () => {
      if (!hasDragCompleted) {
        hasDragCompleted = true;
      }
    });
  }

  /**
   * Creates an empty hundred frame
   * @protected
   */
  protected createEmptyHundredFrame(): void {
    const visualHundredFrame = this.shapesService.createEmptyHundredFrame();
    visualHundredFrame.snapToGrid(this.containerService.getUnit());
    visualHundredFrame.position({
      x: 2 * this.containerService.getUnit(),
      y: 115 * this.containerService.getUnit()
    });
    this.getMenu().add(visualHundredFrame);
    visualHundredFrame.scale({ x: 0.3, y: 0.3 });
    this.createDraggableShape(visualHundredFrame, this.setupEmptyHundredAfterCreateHandler.bind(this) as any);

    const line = new Line({
      points: [
        2 * this.containerService.getUnit(),
        148 * this.containerService.getUnit(),
        52 * this.containerService.getUnit(),
        148 * this.containerService.getUnit(),
      ],
      stroke: 'rgba(255,255,255,0.3)',
      strokeWidth: 2,
    });
    this.getMenuLayer().add(line);
  }

  /**
   * Creates an empty hundred frame
   * @protected
   */
  protected createFilledHundredFrame(): void {
    const visualHundredFrame = this.shapesService.createEmptyHundredFrame();
    visualHundredFrame.snapToGrid(this.containerService.getUnit());
    visualHundredFrame.position({
      x: 29 * this.containerService.getUnit(),
      y: 115 * this.containerService.getUnit()
    });
    this.getMenu().add(visualHundredFrame);
    visualHundredFrame.scale({ x: 0.2, y: 0.2 });
    visualHundredFrame.fillAllEmptySections();
    visualHundredFrame.enableAllTenFrameActive(false);

    this.createDraggableShape(visualHundredFrame, this.setupFilledHundredAfterCreateHandler.bind(this) as any);
  }

  /**
   * After created handler for empty ten frame
   * @param shape
   * @protected
   */
  protected setupEmptyHundredAfterCreateHandler(shape: EmptyHundredFrame): void {
    shape.scale({ x: 0.3, y: 0.3 });
  }

  /**
   * After created handler for empty ten frame
   * @param shape
   * @protected
   */
  protected setupFilledHundredAfterCreateHandler(shape: EmptyHundredFrame): void {
    shape.fillAllEmptySections();
    shape.enableAllTenFrameActive(false);
    let setupComplete = false;

    shape.on('dragend', () => {
      if (!setupComplete) {
        shape.enableAllTenFrameDrag();
        shape.enableAllTenFrameActive();
        setupComplete = true;
        shape.setActive(true);
      }
    });
    this.setupEmptyHundredAfterCreateHandler(shape);
  }

  /**
   * Creates a blue ten frame
   * @protected
   */
  protected createBlueTenFrame(): void {
    const visualHundredFrame = this.shapesService.createBlueHundredFrame();
    visualHundredFrame.snapToGrid(this.containerService.getUnit());
    visualHundredFrame.position({
      x: 16 * this.containerService.getUnit(),
      y: 155 * this.containerService.getUnit()
    });
    this.getMenu().add(visualHundredFrame);
    visualHundredFrame.scale({ x: 0.3, y: 0.3 });

    this.createDraggableShape(visualHundredFrame, this.setupBlueHundredAfterCreateHandler as any);
    this.getMenu().add(visualHundredFrame);
  }

  /**
   * After created handler for empty ten frame
   * @param shape
   * @protected
   */
  protected setupBlueHundredAfterCreateHandler(shape: EmptyHundredFrame): void {
    shape.scale({ x: 0.3, y: 0.3 });
  }

  /**
   * Sets the height of menu
   */
  public resetHeight(): void {
    console.log('the height', this.containerService.getHeight());
    this.menuRect.height(window.innerHeight);
    this.menuRect.width(55 * this.containerService.getUnit());
    this.menuRect.shadowOffset({
      x: 2 * this.containerService.getUnit(),
      y: 5 * this.containerService.getUnit('height')
    });
    this.menuRect.fillLinearGradientEndPoint({
      x: 45 * this.containerService.getUnit(),
      y: this.containerService.getHeight()
    });
  }

  protected createCounter(): void {
    this.counter = 1;
    const upTriangle = new Konva.RegularPolygon({
      x: 40 * this.containerService.getUnit(),
      y: 40 * this.containerService.getUnit(),
      sides: 3,
      radius: 10,
      fill: '#002073',
    });
    this.getMenu().add(upTriangle);

    const downTriangle = new Konva.RegularPolygon({
      x: 40 * this.containerService.getUnit(),
      y: 55 * this.containerService.getUnit(),
      sides: 3,
      radius: 10,
      fill: '#002073',
    });
    downTriangle.rotate(180);
    this.getMenu().add(downTriangle);

    const text = new Konva.Text({
      x: 35 * this.containerService.getUnit(),
      y: 49 * this.containerService.getUnit(),
      width: 10 * this.containerService.getUnit(),
      height: 0.0,
      align: 'center',
      verticalAlign: 'middle',
      fill: 'black',
      fontStyle: 'bold',
      text: this.counter.toString()
    });

    const upHandler = () => {
      this.counter--;
      if (this.counter <= 1) {
        this.counter = 1;
        downTriangle.hide();
      }
      if (this.counter < 10) {
        upTriangle.show();
      }
      text.text(this.counter.toString());
      this.getMenuLayer().draw();
    };

    const downHandler = () => {
      this.counter++;
      if (this.counter >= 10) {
        this.counter = 10;
        upTriangle.hide();
      }
      if (this.counter > 1) {
        downTriangle.show();
      }
      text.text(this.counter.toString());
      this.getMenuLayer().draw();
    };

    downTriangle.hide();
    upTriangle.on('click', () => downHandler());
    upTriangle.on('touchstart', () => downHandler());
    downTriangle.on('click', () => upHandler());
    downTriangle.on('touchstart', () => upHandler());

    this.getMenu().add(text);
  }

  public dragStartOfTile(shape: Tile): void {
    let dragStartActionCompleted = false;

    shape.on('dragstart', () => {
      if (!dragStartActionCompleted) {
        let parentStack = shape;
        let parentStackPrevented = false;
        for (let counter = 2; counter <= this.counter; counter++) {
          const tile = this.shapesService.createTile();
          tile.enableShadow(false);
          parentStack.stackItem(tile, parentStackPrevented);
          parentStackPrevented = true;
          parentStack = tile;
          tile.disableDrag();
        }
        parentStack.enableDrag();
        dragStartActionCompleted = true;
      }
    });
  }
}
