import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ContainerService } from './services/client/container.service';
import { StageService } from './services/stage/stage.service';
import { LayerService } from './services/layer/layer.service';
import { GridService } from './services/grid/grid.service';
import { environment } from '../environments/environment';
import { ShapesService } from './services/tile/shapes.service';
import { LeftMenuService } from './services/left-menu/left-menu.service';
import { ActiveShape, BaseShape, ShapeIsDraggable } from './interfaces/shape-actions';
import { informationEmitter, InformationEmitterKeys } from './utils/information-emitter';
import { filter } from 'rxjs/operators';
import { interval, timer } from 'rxjs';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FreeHandDrawService } from './services/free-hand-draw/free-hand-draw.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoModalComponent } from './components/modals/info-modal/info-modal.component';
import { Tile } from './shapes/tile/tile';
import { EmptyTenFrames } from './shapes/empty-ten-frames/empty-ten-frames';
import { EmptyHundredFrame } from './shapes/empty-hundred-frame/empty-hundred-frame';
import Konva from 'konva';
import Stage = Konva.Stage;
import { YellowTenFrame } from './shapes/yellow-ten-frame/yellow-ten-frame';
import { BlueHundredFrame } from './shapes/blue-hundred-frame/blue-hundred-frame';

interface Window {
  CounterManager: number;
  InMultiMode: boolean;
}

// tslint:disable-next-line:class-name
interface globalThis {
  CounterManager: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  /**
   * Parent container element
   */
  @ViewChild('parentContainer')
  public parentContainer: ElementRef<HTMLDivElement> | undefined;

  /**
   * Stage container element
   */
  @ViewChild('stage')
  public stageContainer: ElementRef<HTMLDivElement> | undefined;


  public activeShape: BaseShape & ActiveShape | null = null;

  public shapeImage = '';

  public inMultiMode = false;

  public activeShapesInMultiMode: BaseShape[] = [];

  constructor(
    public containerService: ContainerService,
    public stageService: StageService,
    public layerService: LayerService,
    public gridService: GridService,
    public shapesService: ShapesService,
    public menuService: LeftMenuService,
    public freeHandDrawService: FreeHandDrawService,
    public dialog: MatDialog
  ) {
    (window as any).CounterManager = 0;
    (window as any).InMultiMode = false;
  }

  @HostListener('window:resize')
  public windowResize(): void {
    this.containerService.reCalculateScale({
      width: window.innerWidth as number,
      height: window.innerHeight as number
    });
    this.menuService.resetHeight();
    this.stageService.resetDimensions();
  }

  ngAfterViewInit(): void {
    informationEmitter.asObservable()
      .pipe(filter((event) => event.key === InformationEmitterKeys.ActiveShapeChanged))
      .subscribe(event => this.setActiveShape(event.shape as any));
    informationEmitter.asObservable()
      .pipe(filter(event => event.key === InformationEmitterKeys.ActiveShapeRemoved))
      .subscribe((event) => this.removeActiveShape(event.shape as any));
    informationEmitter.asObservable().pipe(filter(event => event.key === InformationEmitterKeys.LayerClicked))
      .subscribe((event) => {
        if (event.shape instanceof Stage) {
          informationEmitter.emit({ key: InformationEmitterKeys.ActiveShapeChanged, shape: null, uniqueName: '' });
          timer(10).subscribe(() => this.layerService.getLayer().batchDraw());
        }
      });

    interval(100)
      .pipe(filter(() => !!this.activeShape))
      .subscribe(() => {
        if (!!this.activeShape) {
          this.shapeImage = this.activeShape.toDataURL();
        }
      });
    this.containerService.resetDefaultDimension();
    this.containerService.reCalculateScale({
      width: window.innerWidth as number,
      height: window.innerHeight as number
    });
    this.stageService.initialize(this.stageContainer?.nativeElement.getAttribute('id') as string, window.innerWidth, window.innerHeight);
    this.layerService.initialize();
    this.stageService.addLayer(this.layerService.getLayer());
    this.freeHandDrawService.initSelectionRectangle(this.stageService.getStage(), this.layerService.getLayer());
    this.stageService.addLayer(this.freeHandDrawService.getLayer());
    this.freeHandDrawService.getLayer().moveToTop();
    if (environment.grid.useGrid) {
      this.gridService.initialize();
      this.layerService.addItem(this.gridService.getGrid() as any);
    }
    this.menuService.initialize();
    this.freeHandDrawService.registerDrawEvents();
    this.stageService.getStage().batchDraw();
    this.layerService.initializeMultiItemDrag(this.stageService.getStage(), () => this.activeShapesInMultiMode as any);
  }

  public showMenu(): void {
    this.menuService.getMenuLayer().isVisible() ? this.menuService.getMenuLayer().hide() : this.menuService.getMenuLayer().show();
    this.layerService.getLayer().batchDraw();
    this.stageService.getStage().batchDraw();
  }

  public setActiveShape(shape: BaseShape & ActiveShape): void {
    if (!this.inMultiMode) {
      this.activeShape = shape;
    } else {
      if (!this.activeShapesInMultiMode.find((activeShape) => activeShape?.getUniqueName() === shape?.getUniqueName())) {
        this.activeShapesInMultiMode.push(shape);
      }
    }
  }

  public remove(): void {
    this.activeShape?.triggerRemove();
    this.activeShape = null;
    this.stageService.getStage().batchDraw();
  }

  public detach(): void {
    this.activeShape?.triggerDetach();
    this.stageService.getStage().batchDraw();
  }

  public menuShow(): any {
    return this.menuService.getMenuLayer().isVisible() ? faEyeSlash : faEye;
  }

  public addText(): void {
    const text = this.shapesService.createTextContent();
    text.setAttrs({
      x: 960 * this.containerService.getScale().width,
      y: 540 * this.containerService.getScale().height,
    });
    if (!this.freeHandDrawService.isInDrawState()) {
      text.enableDrag();
    }

    text.scale(this.layerService.getLayer().scale());

    this.layerService.getLayer().add(text);
    this.layerService.getLayer().batchDraw();
  }

  public showInformationModal(): void {
    this.dialog.open(InfoModalComponent);
  }

  public enableMultiMode(): void {
    (window as any).InMultiMode = !(window as any).InMultiMode;
    this.inMultiMode = !this.inMultiMode;

    informationEmitter.emit({
      shape: null,
      uniqueName: '',
      key: this.inMultiMode ? InformationEmitterKeys.InMultiMode : InformationEmitterKeys.NotInMultiMode
    });

    if (!this.inMultiMode) {
      this.activeShapesInMultiMode = [];
      this.activeShape = null;
      this.shapeImage = '';
      informationEmitter.emit({ shape: null, key: InformationEmitterKeys.ActiveShapeChanged, uniqueName: '' });
      timer(20).subscribe(() => this.layerService.getLayer().batchDraw());
    }

    if (this.inMultiMode && !!this.activeShape) {
      this.activeShapesInMultiMode.push(this.activeShape);

      if (this.activeShape instanceof Tile) {
        this.activeShape.setActive(true, true);
      }
    }
  }

  /**
   * Remove active shapes in multi mode
   */
  public removeMultiModeShapes(): void {
    this.activeShapesInMultiMode.forEach(shape => {
      if (!shape) {
        return;
      }
      shape.destroy();
      this.layerService.getLayer().draw();
    });
    this.activeShape = null;
    this.shapeImage = '';
  }

  public removeActiveShape(shape: BaseShape): void {
    if (!this.inMultiMode) {
      return;
    }

    this.activeShapesInMultiMode = this.activeShapesInMultiMode
      .filter(activeShape => activeShape?.getUniqueName() !== shape.getUniqueName());
  }

  public copyShape(shapes: BaseShape[]): void {
    const toCopyShapes = shapes.filter(shape => {
      if (shape instanceof YellowTenFrame) {
        if (!!shape.tenFrame) {
          return false;
        }
      }

      if (shape instanceof BlueHundredFrame) {
        if (!!shape.hundredFrame) {
          return false;
        }
      }

      return true;
    });
    for (const toCopyShape of toCopyShapes) {

      let shape = toCopyShape;

      if (!shape) {
        continue;
      }

      if (shape instanceof Tile) {
        if (!!shape.parentStack) {
          shape = shape.parentStack as Tile;

          while (!!(shape as Tile).parentStack) {
            shape = (shape as Tile).parentStack as Tile;
          }
        }
      }

      const copiedShape = shape.copy() as (BaseShape & ShapeIsDraggable & ActiveShape);
      const position = shape.absolutePosition();
      position.x += 20;
      position.y += 20;
      copiedShape.position(position);
      copiedShape.enableShadow(false);
      copiedShape.enableDrag();

      if (copiedShape instanceof EmptyTenFrames) {
        if (!copiedShape.yellowTenFrame) {
          copiedShape.enableRemovalOfTiles();
          copiedShape.enableAllTilesDrag();
        }
      }

      if (copiedShape instanceof EmptyHundredFrame) {
        copiedShape.allowActivation(true);
        copiedShape.enableAllTenFrameActive(true);
        copiedShape.enableAllTenFrameDrag(true);
      }

      this.layerService.getLayer().add(copiedShape);
    }

    this.layerService.getLayer().batchDraw();
  }

  @HostListener('window:keydown', ['$event'])
  public removeOnDelete($event: KeyboardEvent): void {
    if ($event.key === 'Delete') {
      this.remove();
    }
  }
}
